<template>
    <MainLayout :loading="loading">
        <MainTitle
            :title="`Déclarer <span class='d-none d-lg-inline'>vos titres de presse</span>`"
            text="Pour déclarer la réception ou la non réception, cliquez sur le titre."
            class="mt-4"
        />

        <div v-if="!courrierLoading" class="magazines mt-4">
            <div v-if="quotidiens.length > 0" class="list-group mb-2">
                <button type="button" class="list-group-item list-group-item-action active py-1">
                    Tous les jours
                </button>
                <button
                    v-for="(mag, i) in quotidiens"
                    :key="i"
                    class="list-group-item list-group-item-action"
                    type="button"
                    @click="$router.push('/titre/' + mag.id)"
                >
                    {{ mag.title }}
                </button>
            </div>

            <div v-if="biHebdomadaires.length > 0" class="list-group mb-2">
                <button type="button" class="list-group-item list-group-item-action active py-1">
                    Deux fois par semaine
                </button>
                <button
                    v-for="(mag, i) in biHebdomadaires"
                    :key="i"
                    class="list-group-item list-group-item-action"
                    type="button"
                    @click="$router.push('/titre/' + mag.id)"
                >
                    {{ mag.title }}
                </button>
            </div>

            <div v-if="hebdomadaires.length > 0" class="list-group mb-2">
                <button type="button" class="list-group-item list-group-item-action active py-1">
                    Toutes les semaines
                </button>
                <button
                    v-for="(mag, i) in hebdomadaires"
                    :key="i"
                    class="list-group-item list-group-item-action"
                    type="button"
                    @click="$router.push('/titre/' + mag.id)"
                >
                    {{ mag.title }}
                </button>
            </div>

            <div v-if="biMensuels.length > 0" class="list-group mb-2">
                <button type="button" class="list-group-item list-group-item-action active py-1">
                    Deux fois par mois
                </button>
                <button
                    v-for="(mag, i) in biMensuels"
                    :key="i"
                    type="button"
                    class="list-group-item list-group-item-action"
                    @click="$router.push('/titre/' + mag.id)"
                >
                    {{ mag.title }}
                </button>
            </div>

            <div v-if="mensuels.length > 0" class="list-group mb-2">
                <button type="button" class="list-group-item list-group-item-action active py-1">
                    Tous les mois
                </button>
                <button
                    v-for="(mag, i) in mensuels"
                    :key="i"
                    type="button"
                    class="list-group-item list-group-item-action"
                    @click="$router.push('/titre/' + mag.id)"
                >
                    {{ mag.title }}
                </button>
            </div>
        </div>
        <div v-else class="mt-3 text-center">
            <b-spinner label="Spinner" />
        </div>
    </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle'

export default {
    components: {
        MainLayout,
        MainTitle
    },
    data: () => ({
        mensuels: [],
        biMensuels: [],
        hebdomadaires: [],
        biHebdomadaires: [],
        quotidiens: []
    }),
    created() {
        this.fetchCourriers().then(() => {
            this.mensuels = this.courriers.filter(courrier => courrier.periodicite === 'Mensuel')
            this.biMensuels = this.courriers.filter(courrier => courrier.periodicite === 'Bi-mensuel')
            this.hebdomadaires = this.courriers.filter(courrier => courrier.periodicite === 'Hebdomadaire')
            this.biHebdomadaires = this.courriers.filter(courrier => courrier.periodicite === 'Bi-hebdomadaire')
            this.quotidiens = this.courriers.filter(courrier => courrier.periodicite === 'Quotidien')
        })
    },
    computed: mapGetters({
        loading: 'content/loading',
        courriers: 'courriers/courriers',
        courrierLoading: 'courriers/loading'
    }),
    methods: mapActions({
        fetchCourriers: 'courriers/fetchCourriers'
    })
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';

.magazines {
    max-width: 450px;

    .list-group-item {
        border: none;

        &.active {
            cursor: default;
            font-weight: bold;
            border-radius: $border-radius;
            background-color: $dark;
        }
    }
}
</style>
