import axios from 'axios'

const courriers = {
    namespaced: true,
    state: () => ({
        courriers: [],
        titre: { old: [] },
        loading: false,
        submitLoading: false,
        alert: null
    }),
    getters: {
        courriers: state => state.courriers,
        titre: state => state.titre,
        loading: state => state.loading,
        submitLoading: state => state.submitLoading,
        alert: state => state.alert
    },
    mutations: {
        setCourriers: (state, payload) => (state.courriers = payload),
        setTitre: (state, payload) => (state.titre = payload),
        setLoading: (state, payload) => (state.loading = payload),
        setSubmitLoading: (state, payload) => (state.submitLoading = payload),
        setAlert: (state, payload) => (state.alert = payload)
    },
    actions: {
        /**
         * Récupère les courriers
         * @param commit
         * @returns {Promise<AxiosResponse>}
         */
        fetchCourriers({ commit }) {
            commit('setLoading', true)
            return axios
                .get('/courrier')
                .then(({ data }) => {
                    commit('setLoading', false)
                    commit('setCourriers', data)
                })
                .catch(e => {
                    commit('setLoading', false)
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                })
        },
        /**
         *
         * @param commit
         * @param id
         * @returns {Promise<AxiosResponse>}
         */
        getTitreById({ commit }, id) {
            commit('setLoading', true)
            return axios
                .get('/courrier/' + id)
                .then(({ data }) => {
                    commit('setTitre', data)
                    commit('setLoading', false)
                })
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    commit('setLoading', false)
                })
        },
        /**
         * get Titre name dor notifications
         * @param commit
         * @param id
         * @returns {Promise<AxiosResponse>}
         */
        getTitreNameById({ commit }, id) {
            commit('setLoading', true)
            return axios.get('/courriers/' + id).then(({ data }) => {
                console.log(data.titre)
                return data.titre
            })
        },
        /**
         * Check numero & display declaration
         * @param commit
         * @param payload
         * @returns {Promise<AxiosResponse>}
         */
        checkNumero({ commit }, payload) {
            commit('setLoading', true)
            return axios
                .post('/courriers/checkNumero/' + payload.id, { numero: payload.numero })
                .then(({ data }) => data.action)
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    commit('setLoading', false)
                })
        },
        /**
         * Déclare ou edite
         * @param commit
         * @param payload
         * @param dispatch
         * @returns {Promise<AxiosResponse>}
         */
        declareOrEdit({ commit }, payload) {
            commit('setAlert', null)
            commit('setSubmitLoading', true)
            return axios
                .post('courriers/declareOrEdit', JSON.stringify(payload))
                .then(() => commit('setSubmitLoading', false))
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    commit('setSubmitLoading', false)
                })
            // Votre déclaration du numéro %s du titre %s est enregistrée.
        },
        /**
         * Déclare ou edite
         * @param commit
         * @param payload
         * @param dispatch
         * @returns {Promise<AxiosResponse>}
         */
        declareOrEditQuotidien({ commit }, payload) {
            commit('setAlert', null)
            commit('setSubmitLoading', true)
            return axios
                .post('courriers/declareOrEditQuotidien', JSON.stringify(payload))
                .then(() => commit('setSubmitLoading', false))
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    commit('setSubmitLoading', false)
                })
            // Votre déclaration du numéro %s du titre %s est enregistrée.
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {Promise<void>}
         */
        cancel({ commit, dispatch }, payload) {
            return axios
                .post('courriers/cancel/' + payload.titreId + '/' + payload.numero)
                .then(() => {
                    commit('setAlert', {
                        type: 'info',
                        text: `Déclaration pour le numéro ${payload.numero} annulée.`
                    })
                    dispatch('getTitreById', payload.titreId)
                })
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                })
            // Votre déclaration du numéro %s du titre %s est enregistrée.
        },
        /**
         * Déclare non recu
         * @param commit
         * @param dispatch
         * @param titreId
         * @returns {Promise<AxiosResponse>}
         */
        declareNonRecu({ commit, dispatch }, titreId) {
            return axios
                .post('/courriers/signalNR/' + titreId)
                .then(() => {
                    commit('setAlert', {
                        text:
                            'Votre non réception a bien été prise en compte. Dès réception de ce dernier, merci de réaliser la déclaration.',
                        duration: 10000,
                        type: 'success'
                    })
                    dispatch('getTitreById', titreId)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param payload
         * @param id
         */
        cancelNonRecu({ commit, dispatch }, payload) {
            return axios
                .post('/courriers/cancelNR/' + payload.id)
                .then(() => {
                    commit('setAlert', {
                        // eslint-disable-next-line no-irregular-whitespace
                        text: `Signalememt annulé, merci de réaliser la déclaration.`,
                        type: 'success'
                    })
                    dispatch('getTitreById', payload.titreId)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        }
    }
}

export default courriers
