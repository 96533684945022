<template>
    <MainLayout class="faq">
        <MainTitle title="Foire aux questions" text="" />
        <b-card v-for="(qr, i) in faq" :key="i" no-body class="border-0 mb-3">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle="'accordion-' + i" block href="#" variant="link">{{ qr.question }}</b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + i" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text>{{ qr.response }}</b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </MainLayout>
</template>

<script>
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'

export default {
    components: {
        MainLayout,
        MainTitle
    },
    data: () => ({
        faq: [
            {
                question: 'Où trouver mon identifiant de panéliste ?',
                response: `Votre identifiant est un numéro à 6 chiffres.
Il vous a été communiqué par mail au tout début de votre participation.
Il est également noté dans l’encadré en haut de votre carnet de bord.`
            },
            {
                question: 'Et mon mot de passe ?',
                response: `Il correspond, si vous ne l'avez pas changé, au jour et au mois de votre date naissance
(JJMM).
Si vous l’avez oublié après l’avoir changé, vous pouvez le recevoir à nouveau par mail, en
actionnant “mot de passe oublié ?”.`
            },
            {
                question: `Je n'arrive pas à me connecter au site Internet ?`,
                response: `Vous pouvez contacter notre équipe au 0805 56 44 21 ou au 01 57 00 57 76. L'appel est gratuit depuis un poste fixe.`
            },
            {
                question: 'Je ne me souviens pas de la date de réception.',
                response: `Déclarez la réception de votre journal ou de votre magazine en saisissant sa date imprimée
et en cochant la mention “date incertaine” comme date de réception.
Un conseil : à la réception d'un exemplaire de la mesure, notez immédiatement sur le film
plastique et sur votre carnet de bord, la date de sa réception.`
            },
            {
                question: 'La date de cachet est illisible.',
                response: `Vous devez déclarer quand même votre journal ou magazine, en indiquant la date de
réception et le distributeur.
Sur le site Internet : cochez “la date imprimée est illisible ou absente”`
            },
            {
                question: `J'ai oublié de déclarer les journaux ou magazines reçus du début de semaine ?`,
                response: `N'attendez plus. Déclarez-les au plus vite en indiquant « date incertaine » si vous n’êtes pas
sûr(e) et certain(e) de la date de réception.`
            },
            {
                question: `Que dois-je faire si je dois m'absenter ou partir en
vacances ?`,
                response: `Au moins 3 semaines à l'avance, connectez-vous sur le site Internet ou appelez la hotline
pour déclarer vos dates d'absence de votre domicile. L'appel est gratuit depuis un poste fixe.`
            },
            {
                question: `Je n'ai pas déclaré mes congés à l'avance et je pars dans
moins de 3 semaines. Que dois-je faire ?`,
                response: `N'attendez plus et déclarez-les au plus vite ! A votre retour, ne manquez pas de déclarer les
exemplaires reçus - s'il y en a eu - même si vous n'avez pas leurs dates de réception.`
            },
            {
                question: `Pourrai-je me faire remplacer pendant une absence de
courte de durée : un ou deux jours ?`,
                response: `Oui, vous pouvez vous faire remplacer par l'un de vos proches à condition qu'il relève bien
votre courrier chaque jour. Il devra noter soigneusement la date de réception sur la
première page de chaque exemplaire de presse sans jeter le film plastique. À votre retour,
vous n'aurez plus qu'à renseigner le carnet de bord et à effectuer vos déclarations.`
            },
            {
                question: `Je ne suis pas la seule personne de ma famille à aller
chercher le courrier.`,
                response: `Expliquez aux autres membres de la famille qu'il ne faut pas jeter les exemplaires qui vous
sont adressés. Faites lire, lorsque c'est possible, le manuel du panéliste aux membres de
votre famille. Ceci vous aidera à disposer de tous les revues qui vous sont adressés dans le
cadre de cette étude et à les déclarer en ayant toutes les informations nécessaires.`
            },
            {
                question: `Confidentialité`,
                response: `Pour utiliser vos déclarations et produire des résultats fiables et impartiaux, votre
participation à l'étude doit rester confidentielle vis-à-vis des services postaux (votre bureau
de poste, votre facteur, etc…).`
            },
            {
                question: `Combien de temps dois-je participer ?`,
                response: `Vous pouvez participer aussi longtemps que possible à cette expérience. Cependant vous
pouvez mettre fin à votre participation à tout moment en appelant la hotline pour convenir
avec un opérateur d'une date de sortie du panel.`
            },
            {
                question: `Je ne veux plus participer, que dois-je faire ?`,
                response: `Merci de contacter au plus vite les opérateurs par mail ou par téléphone afin de valider avec
vous la date de fin de votre participation.`
            },
            {
                question: `Et si je déménage ?`,
                response: `Si vos coordonnées personnelles (adresses, téléphone fixe, portable, email) changent, merci
de prévenir les opérateurs par téléphone au 0805 564 421 / 01 57 00 57 76 ou par mail, afin
de modifier vos données.`
            },
            {
                question: `Je préfèrerais être contacté(e) par mail / par sms,
comment dois-je faire ?`,
                response: `Contactez notre équipe par mail (contact@csa.mesure-presse.fr) ou par téléphone 0805 564
421 / 01 57 00 57 76. A noter que ces contacts écrits ne remplaceront pas les contacts
téléphoniques, indispensables au bon suivi de la mesure.`
            }
        ]
    })
}
</script>

<style lang="scss">
.faq {
    .card-header {
        .btn {
            position: relative;
            padding-left: 3em;
            text-align: left;

            &:before {
                content: '\2212';
                position: absolute;
                margin-left: -2em;
            }

            &.collapsed {
                &:before {
                    content: '\002B';
                }
            }
        }
    }
}
</style>
