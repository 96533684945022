<template>
    <MainLayout class="titre">
        <MainTitle :title="titre.titre" :text="'Nous sommes le ' + today" />

        <b-list-group>
            <b-list-group-item>
                <b-row class="d-none d-md-flex">
                    <div class="col-2">Parution</div>
                    <div class="col-3">
                        Reçu
                        <button
                            v-if="confort"
                            class="btn ml-2 py-0"
                            :class="{ 'btn-primary': confortChecked, 'btn-outline-primary': !confortChecked }"
                            title="J'ai reçu les autres exemplaires dans les délais."
                            data-content="Vous pouvez cliquer pour mettre tous les exemplaires sans déclaration à reçu le jour même."
                            @click="confortAction"
                        >
                            <i class="fa fa-check" />
                        </button>
                    </div>
                    <div class="col-2">Date de réception</div>
                    <div v-if="titreId === '72' || titreId === '4804'" class="col-2">Date imprimée</div>
                    <div v-if="titreId === '72' || titreId === '4804'" class="col-2">
                        Date imprimée illisible/absente
                    </div>
                    <div class="col-1">Distribué par La Poste</div>
                    <!-- <div v-if="titreId === '72' || titreId === '4804'" class="col-1">Distribué par La Poste</div> -->
                </b-row>
            </b-list-group-item>
            <QuotidienRow
                v-for="declaration in declarations"
                :key="declaration.numero"
                :declaration="declaration"
                @onRowChanged="onRowChanged"
            />
        </b-list-group>

        <!-- <fieldset class="form-group mt-5">
            <div class="row">
                <legend class="col-form-label col-md-1 pt-0">
                    Distribué par <u>La Poste</u> avec ce logo/mention <span class="text-danger">*</span>
                </legend>
                <div class="col-md-9">
                    <b-form-group>
                        <b-form-radio v-model="distribution" value="1" style="display: inline-block; margin-right: 1em">
                            Oui
                        </b-form-radio>
                        <b-form-radio v-model="distribution" value="0" style="display: inline-block; margin-right: 1em">
                            Non
                        </b-form-radio>
                    </b-form-group>
                    <b-form-checkbox v-if="distribution === '0'" v-model="distributionConfirmation">
                        Confirmez-vous que cet exemplaire n’a pas été distribué par La Poste ?
                    </b-form-checkbox>
                </div>
            </div>
        </fieldset> -->

        <div class="form-group row mt-5">
            <div class="col-md-4">
                Les titres distribués par La Poste sont souvent sous film plastique et identifiables grâce au logo
                suivant ou à la mention <strong>PRESSE DISTRIBUÉE PAR LA POSTE</strong>.
            </div>
            <div class="col-md-8">
                <img :src="getLogo1" class="mt-4 mt-md-0" alt="" />
                <img :src="getLogo2" class="mt-4 mt-md-0 ml-md-5" alt="" />
            </div>
        </div>

        <div class="row justify-content-between my-4 px-3">
            <button type="button" class="btn btn-secondary mt-4" @click="$router.go(-1)">Retour</button>
            <button v-b-tooltip.hover type="submit" class="btn btn-primary mt-4" @click="onSubmit">
                <!-- :disabled="!isValidForm" -->

                <!-- :title="isValidForm ? '' : 'Veuillez remplir tous les champs requis.'" -->
                <span v-if="submitLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                Valider
            </button>
        </div>

        <b-modal ref="my-modal" hide-footer title="Using Component Methods">
            <div class="d-block text-center">
                <p>Vous n'avez-pas déclaré tous les exemplaires de la semaine. Souhaitez-vous le faire maintenant ?</p>
            </div>
            <b-button class="mt-3" variant="outline-danger" block @click="$root.$emit('bv::hide::modal', 'modal-1')">
                Rester
            </b-button>
            <b-button class="mt-2" variant="outline-warning" block @click="() => onSubmit(true)">Ok</b-button>
        </b-modal>
    </MainLayout>
</template>

<script>
import Vue from '../main'
import { mapActions, mapGetters } from 'vuex'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import QuotidienRow from '../components/QuotidienRow.vue'
import moment from '@/lib/moment-ferie-fr'
import axios from 'axios'

export default {
    //permet d'utiliser les imports des composants
    components: {
        MainLayout,
        MainTitle,
        QuotidienRow
    },
    //déclarer des variables global (dans le fichier)
    data() {
        return {
            todayRaw: moment(),
            today: moment().format('dddd D MMMM YYYY'),
            titreId: this.$route.params.titreId,
            semaine: moment(this.$route.params.semaine, 'x'),
            envoiRelanceId: this.$route.params.envoiRelanceId,
            // distribution: '',
            // distributionConfirmation: true,
            formValues: {},
            declarations: null,
            confort: null,
            confortChecked: false
        }
    },
    created() {
        if (this.titreId) {
            // noinspection JSCheckFunctionSignatures
            this.getTitreById(this.titreId)

            // Mettre dans store !
            return axios
                .get(
                    'courriers/declareOrEditQuotidien/' +
                        moment(this.$route.params.semaine, 'x').format('YYYY[-]ww') +
                        '/' +
                        this.titreId
                )
                .then(({ data }) => {
                    // parution 5 jours / 7
                    if (this.titreId === '42' || this.titreId === '79') this.declarations = data.Declaration.slice(0, 5)
                    else if (this.titreId === '72' || this.titreId === '4804') this.declarations = data.Declaration.slice(1, 6)
                    else this.declarations = data.Declaration.slice(0, 6)
                    this.confort = data.confort
                })
        }
    },
    computed: {
        ...mapGetters({
            submitLoading: 'courriers/submitLoading',
            titre: 'courriers/titre',
            user: 'user/fullUser'
        }),
        // isValidForm() {
        //     return this.distribution === '1' || this.distributionConfirmation
        // },
        getLogo1() {
            switch (this.titre['categorie_presse_id']) {
                case 'PNU':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_logo_0_140px.jpg'
                case 'PMU':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_urgente_logo_0_140px.jpg'
                case 'PMU2':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P2_140px.jpg'
                case 'PECO':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_eco_logo_0_140px.jpg'
                case 'PQA':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_urgente_logo_0_140px.jpg'
                default:
                    return process.env.VUE_APP_BO_URL + '/img/Presse_logo_0_140px.jpg'
            }
        },
        getLogo2() {
            switch (this.titre['categorie_presse_id']) {
                case 'PNU':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P4_140px.jpg'
                case 'PMU':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P1_140px.jpg'
                case 'PECO':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P7_140px.jpg'
                default:
                    return ''
            }
        }
    },
    methods: {
        ...mapActions({
            getTitreById: 'courriers/getTitreById',
            declareOrEditQuotidien: 'courriers/declareOrEditQuotidien'
        }),
        onRowChanged(data) {
            if (data.value.recu && data.value.recu !== 'jour') this.confortChecked = false
            this.formValues[data.numero] = {
                ...this.formValues[data.numero],
                ...data.value
            }
        },
        onSubmit() {
            // Refacto possible
            // if (!this.isValidForm) return

            const data = {
                titre_id: this.titreId,
                week: moment(this.$route.params.semaine, 'x').format('YYYY[-]ww'),
                Declaration: {}
                // DeclarationSemaine: { distribue_laposte: this.distribution === '1' }
                // DeclarationSemaine: { distribue_laposte: this.distribution === '1' }
            }

            if (this.envoiRelanceId) data.envoiRelance = this.envoiRelanceId

            for (const elem in this.formValues) {
                if (!elem || elem == 'undefined') break
                const fromAPI = this.declarations.find(decla => decla.numero === elem)
                // pour la Validatation
                if (this.formValues[elem].recu !== undefined) fromAPI.statut_quotidien = this.formValues[elem].recu
                // return
                if (this.formValues[elem].distributionConfirmation !== undefined)
                    fromAPI.distributionConfirmation = this.formValues[elem].distributionConfirmation

                if (
                    this.formValues[elem].distribue_laposte == 'false' &&
                    this.formValues[elem].distributionConfirmation != true
                ) {
                    this.formValues[elem].distribue_laposte = true
                }

                if (this.formValues[elem].distribue_laposte !== undefined)
                    fromAPI.distribue_laposte = this.formValues[elem].distribue_laposte
                if (fromAPI.statut_quotidien == 'pas_encore') fromAPI.distribue_laposte = false
                if (fromAPI.distribue_laposte == 'true') fromAPI.distribue_laposte = true
                if (fromAPI.distribue_laposte == 'false') fromAPI.distribue_laposte = false

                if (this.formValues[elem].noDateImprimee !== undefined)
                    fromAPI.date_imprimee_incertaine = this.formValues[elem].noDateImprimee
                if (this.formValues[elem].dateReception !== undefined)
                    fromAPI.date_reception = this.formValues[elem].dateReception

                if (this.formValues[elem].dateImprimee !== undefined)
                    fromAPI.date_imprimee = this.formValues[elem].dateImprimee

                if (
                    ((fromAPI.statut_quotidien === 'avance' || fromAPI.statut_quotidien === 'retard') &&
                        fromAPI.date_reception &&
                        moment(fromAPI.date_reception, 'DD/MM/YYYY').isAfter(moment())) ||
                    (!fromAPI.date_imprimee_incertaine &&
                        fromAPI.date_imprimee &&
                        moment(fromAPI.date_imprimee, 'DD/MM/YYYY').isAfter(moment()))
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `Les dates ne peuvent pas être supérieure à la date d'aujourd'hui pour le ${moment(
                            elem,
                            'YYYY-MM-DD'
                        ).format('DD/MM/YYYY')}.`
                    })
                    return
                } else if (
                    fromAPI.date_reception &&
                    (moment(fromAPI.date_reception, 'DD/MM/YYYY').isFerie(this.user.departement) ||
                        moment(fromAPI.date_reception, 'DD/MM/YYYY').format('dddd') === 'Sunday' ||
                        moment(fromAPI.date_reception, 'DD/MM/YYYY').format('dddd') === 'dimanche')
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `La date de réception ne peut pas être dimanche ou jour férié pour le ${moment(
                            elem,
                            'YYYY-MM-DD'
                        ).format('DD/MM/YYYY')}.`
                    })
                    return
                }

                if (
                    fromAPI.date_reception &&
                    fromAPI.date_imprimee &&
                    moment(moment(fromAPI.date_imprimee)).isAfter(
                        moment(fromAPI.date_reception)
                    ) &&
                    (this.titreId === '72' || this.titreId === '4804')
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `La date imprimée doit être inférieure ou égale à la date de réception pour le ${moment(
                            elem,
                            'YYYY-MM-DD'
                        ).format('DD/MM/YYYY')}.`
                    })

                    return
                } else if (
                    !fromAPI.date_imprimee &&
                    !fromAPI.date_imprimee_incertaine &&
                    (this.titreId === '72' || this.titreId === '4804') &&
                    this.formValues[elem].recu !== 'pas_encore'
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `Veuillez saisir  date imprimée ou selectionnez "Date imprimée illisible/absente" pour le ${moment(
                            elem,
                            'YYYY-MM-DD'
                        ).format('DD/MM/YYYY')}.`
                    })
                    return
                } else if (
                    (fromAPI.statut_quotidien === 'avance' || fromAPI.statut_quotidien === 'retard') &&
                    !fromAPI.date_reception
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `Veuillez saisir une date de réception pour le ${moment(elem, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY'
                        )}.`
                    })
                    return
                } else if (
                    fromAPI.distribue_laposte != true &&
                    fromAPI.distribue_laposte != false &&
                    fromAPI.statut_quotidien != 'pas_encore'
                ) {
                    window.scrollTo(0, 120)
                    this.$notify({
                        duration: 8000,
                        type: 'info',
                        text: `Veuillez renseigner Distribué par La Poste pour le ${moment(elem, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY'
                        )}.`
                    })
                    return
                } else {
                    data.Declaration[elem] = {
                        titre_id: this.titreId,
                        numero: elem,
                        distribue_laposte: fromAPI.distribue_laposte,
                        titre_du_quotidien: this.titre.titre
                    }

                    data.Declaration[elem]['id'] = fromAPI.id ? fromAPI.id : null
                    data.Declaration[elem]['statut_quotidien'] = fromAPI.statut_quotidien
                    data.Declaration[elem]['date_imprimee_incertaine'] = fromAPI.date_imprimee_incertaine

                    if (
                        fromAPI.statut_quotidien !== 'pas_encore' &&
                        fromAPI.statut_quotidien !== 'incertain' &&
                        this.formValues[elem].dateReception instanceof Date
                    )
                        data.Declaration[elem]['date_reception'] = moment(this.formValues[elem].dateReception).format(
                            'DD/MM/YYYY'
                        )

                    if (
                        !data.Declaration[elem]['date_imprimee_incertaine'] &&
                        this.formValues[elem].dateImprimee instanceof Date
                    )
                        data.Declaration[elem]['date_imprimee'] = moment(this.formValues[elem].dateImprimee).format(
                            'DD/MM/YYYY'
                        )
                }
            }

            data.Declaration = Object.values(data.Declaration)

            // if (data.Declaration.length < 6 && !modal) this.$root.$emit('bv::show::modal', 'modal-1')
            // https://bootstrap-vue.org/docs/components/modal
            // else
            this.declareOrEditQuotidien(data).then(() => {
                this.$router.push('/titre/' + this.titreId)
            })
        },
        confortAction() {
            if (!this.confortChecked) {
                this.confortChecked = true
                Vue.$emit('confort')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';
</style>
