import axios from 'axios'
// import { initPushWoosh } from '../pushHandler'

const auth = {
    namespaced: true,
    state: () => ({
        loading: false,
        altLoading: false,
        user: null,
        fullUser: {
            civilite_titre: null,
            nom: null,
            prenom: null,
            app_bal_etage_couloir_esc: null,
            entree_bat_immeuble_residence: null,
            num_voie: null,
            libelle_voie: null,
            lieu_dit_spd: null,
            code_postal: null,
            departement: null,
            localite: null,
            email: null,
            telephone: null,
            telephone_2: null,
            bal_normalise: null
        },
        notifications: null,
        alert: null
    }),
    getters: {
        loading: state => state.loading,
        altLoading: state => state.altLoading,
        user: state => state.user,
        fullUser: state => state.fullUser,
        notifications: state => state.notifications,
        alert: state => state.alert
    },
    mutations: {
        setLoading: (state, payload) => (state.loading = payload),
        setAltLoading: (state, payload) => (state.altLoading = payload),
        setUser: (state, payload) => (state.user = payload),
        setFullUser: (state, payload) => (state.fullUser = payload),
        setNotifications: (state, payload) => (state.notifications = payload),
        setAlert: (state, payload) => (state.alert = payload)
    },
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @returns {Promise<AxiosResponse>}
         */
        fetchUser({ commit /*, dispatch*/ }) {
            return axios
                .get('/user/me')
                .then(({ data }) => {
                    commit('setUser', data)
                    // if (process.env.NODE_ENV !== 'development') {
                    //     // Push Woosh init with user id
                    //     initPushWoosh(`${data.id}`)
                    // }
                })
                .catch(e => {
                    console.log('')
                    // e.request && e.request.status && e.request.status === 401
                    //     ? dispatch('logout')
                    //     : commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                })
        },
        /**
         * Get full paneliste infos
         * @param commit
         * @param dispatch
         * @returns {Promise<AxiosResponse>}
         */
        fetchFullPaneliste({ commit /*, dispatch */ }) {
            commit('setLoading', true)
            return axios
                .get('/user/full')
                .then(({ data }) => {
                    commit('setFullUser', data)
                    commit('setLoading', false)
                })
                .catch(e => {
                    console.log('')
                    // commit('setLoading', false)
                    // e.request && e.request.status && e.request.status === 401
                    //     ? dispatch('logout')
                    //     : commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                })
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {Promise<AxiosResponse>}
         */
        sendAccord({ commit, dispatch }, payload) {
            commit('setAltLoading', true)
            return axios
                .post('/user/accord', payload)
                .then(({ data }) => {
                    return dispatch('fetchUser').then(() => {
                        commit('setAltLoading', false)
                        return data
                    })
                })
                .catch(e => {
                    commit('setAltLoading', false)
                    commit(
                        'setAlert',
                        e.request && e.request.status && e.request.status === 400
                            ? { ...e, text: `Mauvais identifiant` }
                            : { ...e, text: `Une erreur s'est produite` }
                    )
                })
        },
        /**
         *
         */
        sendValidation({ commit, dispatch }, payload) {
            commit('setAltLoading', true)
            return axios
                .post('/user/validation', payload)
                .then(() => {
                    return dispatch('fetchUser').then(() => {
                        commit('setAltLoading', false)
                    })
                })
                .catch(e => {
                    commit('setAltLoading', false)
                    commit(
                        'setAlert',
                        e.request && e.request.status && e.request.status === 400
                            ? { ...e, text: `Mauvais identifiant` }
                            : { ...e, text: `Une erreur s'est produite` }
                    )
                })
        },
        /**
         *
         * @param commit
         * @param payload
         */
        fetchNotifications({ commit }, payload) {
            return axios
                .get('/user/notifications', payload)
                .then(({ data }) => {
                    commit('setNotifications', data)
                })
                .catch(e => {
                    commit(
                        'setAlert',
                        e.request && e.request.status && e.request.status === 400
                            ? { ...e, text: `Mauvais identifiant` }
                            : { ...e, text: `Une erreur s'est produite` }
                    )
                })
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param id
         */
        deleteNotification({ commit, dispatch }, id) {
            return axios
                .post('/user/removeNotificationMessage' + id)
                .then(() => dispatch('fetchNotifications'))
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        }
    }
}

export default auth
