<template>
    <MainLayout :loading="loading" class="absences">
        <b-row class="d-sm-block d-md-flex">
            <b-col class="mb-5">
                <MainTitle title="Mes absences" text="Veuillez renseigner ici les dates de vos vacances" />
                <div class="d-flex">
                    <Datepicker
                        v-model="absenceStart"
                        format="dd/MM/yyyy"
                        :monday-first="true"
                        :language="fr"
                        :disabled-dates="{ to: new Date() }"
                        placeholder="Date de début"
                        :bootstrap-styling="true"
                        :input-class="`datepicker first ${editId ? 'edited' : ''}`"
                    >
                        <span slot="afterDateInput" class="datepicker-icon">
                            <CalendarIcon size="1.1x" class="text-primary" />
                        </span>
                    </Datepicker>

                    <Datepicker
                        v-model="absenceEnd"
                        format="dd/MM/yyyy"
                        :monday-first="true"
                        :language="fr"
                        :disabled-dates="{
                            to: hiddenDate
                        }"
                        placeholder="Date de fin"
                        :bootstrap-styling="true"
                        :input-class="`datepicker last ${editId ? 'edited' : ''}`"
                    >
                        <span slot="afterDateInput" class="datepicker-icon chevron">
                            <i class="fa fa-chevron-right text-secondary" aria-hidden="true" />
                        </span>
                    </Datepicker>
                </div>
                <p class="mt-2 small" style="max-width: 320px">
                    Pour ne pas encombrer votre boîte nous pouvons suspendre l'abonnement des quotidiens pendant votre absence. Votre absence doit être d'un minimum 15 jours et vous devez l'enregistrer 20 jours avant votre départ.
                </p>
                <b-form-checkbox v-model="suspendreQuotidiens" class="mb-2 mr-lg-5 text-dark">
                    Suspendre les quotidiens
                </b-form-checkbox>
                <div
                    v-if="editId"
                    class="position-relative cursor-pointer"
                    style="max-width: 320px;"
                    @click="cancelEdit"
                >
                    <div class="position-absolute" style="right: 0;">
                        <Edit3Icon size="1x" class="text-secondary" />
                        <span class="text-secondary"> annuler </span>
                    </div>
                </div>
                <b-button variant="primary" class="mt-3" @click="submit">
                    <span v-if="submitLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                    Valider
                </b-button>

                <div v-if="nextAbsences.length > 0" class="mt-4">
                    <b-card
                        v-for="(absence, i) in nextAbsences"
                        :key="i"
                        class="box-shadow absence-card mt-3"
                        :class="editId && editId === absence.id ? 'bg-light' : ''"
                    >
                        <div class="d-flex justify-content-between">
                            <div>{{ absence.debut }}</div>
                            <div><i class="fa fa-long-arrow-right" aria-hidden="true" /></div>
                            <div>{{ absence.fin }}</div>
                            <div>
                                <button
                                    class="btn btn-white mr-1 mt-n1 p-1 pl-sm-4 pr-sm-2"
                                    @click="() => edit(absence)"
                                >
                                    <EditIcon size="1.3x" class="text-secondary" />
                                </button>
                                <button
                                    class="btn btn-white mt-n1 p-1 px-sm-3"
                                    @click="() => remove(absence.id, absence.debut)"
                                >
                                    <Trash2Icon size="1.3x" class="text-danger" />
                                </button>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div v-else class="mt-4">Pas d'absence prévue.</div>

                <div v-if="oldAbsences.length > 0">
                    <h4 class="mt-5">Historique</h4>
                    <b-card v-for="(absence, i) in oldAbsences" :key="i" class="absence-card mt-3 bg-light">
                        <div class="d-flex justify-content-between">
                            <div>{{ absence.debut }}</div>
                            <div><i class="fa fa-long-arrow-right" aria-hidden="true" /></div>
                            <div>{{ absence.fin }}</div>
                            <div class="px-5"></div>
                        </div>
                    </b-card>
                </div>
            </b-col>

            <b-col class="mb-5">
                <MainTitle
                    title="Mes indisponibilités"
                    text="Merci de nous préciser les plages horaires durant lesquelles vous ne souhaitez pas qu'on vous appelle"
                />
                <b-row>
                    <b-col class="mt-2">
                        <span class="legende yellow">Indisponible</span>
                    </b-col>
                    <b-col class="mt-2">
                        <span class="legende white">Disponible</span>
                    </b-col>
                </b-row>
                <div v-for="(indispo, day) in disponibilitities" :key="day">
                    <b-row class="mt-4">
                        <b-col class="font-weight-bold">{{ getFullDayName(day) }}</b-col>
                    </b-row>
                    <b-row class="timeSlots mt-2" align-h="around">
                        <button
                            class="btn btn-sm timeSlot"
                            :class="indispo['10-12'] ? 'btn-primary' : 'btn-light'"
                            @click="() => toggleIndisponibilities({ day, indispo: '10-12' })"
                        >
                            10-12h
                        </button>
                        <button
                            class="btn btn-sm timeSlot"
                            :class="indispo['12-14'] ? 'btn-primary' : 'btn-light'"
                            @click="() => toggleIndisponibilities({ day, indispo: '12-14' })"
                        >
                            12-14h
                        </button>
                        <button
                            class="btn btn-sm timeSlot"
                            :class="indispo['14-18'] ? 'btn-primary' : 'btn-light'"
                            @click="() => toggleIndisponibilities({ day, indispo: '14-18' })"
                        >
                            14-18h
                        </button>
                        <button
                            class="btn btn-sm timeSlot"
                            :class="indispo['18-20'] || day === 'sam' ? 'btn-primary' : 'btn-light'"
                            :disabled="day === 'sam'"
                            @click="() => toggleIndisponibilities({ day, indispo: '18-20' })"
                        >
                            {{ day === 'sam' ? '' : '18-20h' }}
                        </button>
                    </b-row>
                    <hr />
                </div>
            </b-col>
        </b-row>
    </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale/translations/fr'
import { CalendarIcon, Edit3Icon, EditIcon, Trash2Icon } from 'vue-feather-icons'

export default {
    components: {
        MainLayout,
        MainTitle,
        Datepicker,
        CalendarIcon,
        EditIcon,
        Edit3Icon,
        Trash2Icon
    },
    data: () => ({
        fr,
        absenceStart: null,
        absenceEnd: null,
        suspendreQuotidiens: false,
        editId: null,
        moment
    }),
    created() {
        this.fetchIndisponibilities()
        this.fetchAbsences()
    },
    computed: {
        ...mapGetters({
            loading: 'absences/loading',
            submitLoading: 'absences/submitLoading',
            disponibilitities: 'absences/indisponibilities',
            absences: 'absences/absences'
        }),
        nextAbsences() {
            return this.absences.filter(el => moment().isBefore(moment(el.fin, 'DD/MM/YYYY')))
        },
        oldAbsences() {
            return this.absences.filter(el => moment().isAfter(moment(el.fin, 'DD/MM/YYYY')))
        },
        hiddenDate() {
            return this.absenceStart
                ? moment(this.absenceStart).add(1, 'days').toDate()
                : moment().add(1, 'days').toDate()
        }
    },
    methods: {
        ...mapActions({
            fetchIndisponibilities: 'absences/fetchIndisponibilities',
            fetchAbsences: 'absences/fetchAbsences',
            addAbsence: 'absences/addAbsence',
            deleteAbsence: 'absences/deleteAbsence',
            toggleIndisponibilities: 'absences/toggleIndisponibilities'
        }),
        getFullDayName(day) {
            switch (day) {
                case 'lun':
                    return 'Lundi'
                case 'mar':
                    return 'Mardi'
                case 'mer':
                    return 'Mercredi'
                case 'jeu':
                    return 'Jeudi'
                case 'ven':
                    return 'Vendredi'
                case 'sam':
                    return 'Samedi'
                default:
                    return ''
            }
        },
        submit() {
            this.addAbsence({
                debut: moment(this.absenceStart).format('DD/MM/YYYY'),
                fin: moment(this.absenceEnd).format('DD/MM/YYYY'),
                suspendreQuotidiens: this.suspendreQuotidiens,
                id: this.editId
            }).then(() => {
                this.absenceStart = null
                this.absenceEnd = null
                this.suspendreQuotidiens = false
                this.editId = null
            })
        },
        edit(absence) {
            this.editId = absence.id
            this.absenceStart = moment(absence.debut, 'DD/MM/YYYY').format('MM/DD/YYYY')
            this.absenceEnd = moment(absence.fin, 'DD/MM/YYYY').format('MM/DD/YYYY')
            this.suspendreQuotidiens = absence.suspendre_quotidiens
            window.scrollTo(0, 0)
        },
        remove(id, s) {
            const start = moment(s, 'DD/MM/YYYY')
            const now = moment()
            if (start.isBefore(now))
                this.$notify({
                    text: 'Vous ne pouvez pas supprimer une absence en cours !',
                    duration: 15000
                })
            else this.deleteAbsence(id)
        },
        cancelEdit() {
            this.editId = null
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/bootstrap';

.absences {
    .vdp-datepicker {
        width: 163px;
        margin-right: 0;

        .datepicker-icon {
            position: absolute;
            top: 6px;
            left: 18px;

            &.chevron {
                top: 9px;
                left: 10px;
            }
        }

        .datepicker {
            background: transparent !important;
            border-radius: 0 !important;
            padding: 0 $input-padding-x;
            padding-left: 51px !important;

            &.first {
                border-bottom-left-radius: $border-radius !important;
                border-top-left-radius: $border-radius !important;
                border-right: none;
            }

            &.last {
                border-bottom-right-radius: $border-radius !important;
                border-top-right-radius: $border-radius !important;
                border-left: none;
            }

            &.edited {
                border-color: $primary;
                border-width: 3px;
            }
        }
    }

    .absence-card {
        max-width: 450px;
        border-radius: $border-radius;
    }

    .legende {
        position: relative;
        margin-left: 36px;

        &::before {
            content: '';
            position: absolute;
            top: 3px;
            left: -22px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            border: 1px solid $gray-900;
        }

        &.yellow {
            &::before {
                background: $primary;
            }
        }

        &.white {
            &::before {
                background: $white;
            }
        }
    }

    .timeSlots {
        .timeSlot {
            width: 20%;
            font-size: small;
            text-transform: lowercase !important;
            letter-spacing: 0;
            border-radius: 50px;
        }
    }

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-top: 2px solid $gray-300;
    }
}
</style>
