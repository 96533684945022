<template>
    <div class="pre-layout">
        <main class="container px-2 px-sm-5 py-4 bg-white">
            <Logos />
            <div class="row justify-content-md-center mt-5">
                <div class="col col-md-12 col-lg-6">
                    <slot />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Logos from './Logos'
export default {
    components: {
        Logos
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';

.pre-layout {
    main {
        min-height: calc(100vh - 390px);
        margin-top: 1em;
        border-radius: 0;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);

        @include media-breakpoint-up(sm) {
            border-radius: $border-radius;
            margin-top: 10em;
        }
    }
}
</style>
