<template>
    <footer class="container bg-dark text-light px-2 px-sm-5 py-5">
        <b-row>
            <b-col sm="9">
                <router-link to="/a-propos" class="text-light"><h4>À PROPOS</h4></router-link>
                <h4>NOUS CONTACTER</h4>

                <div class="d-flex mt-3">
                    <div class="w100p">Email</div>
                    <div>
                        <a href="mailto:contact@csa.mesure-presse.fr" class="badge badge-success"
                            >contact@csa.mesure-presse.fr</a
                        >
                    </div>
                </div>

                <div class="d-flex mt-1">
                    <div class="w100p">Hotline</div>
                    <div>
                        <a href="tel:0805 56 44 21" class="badge badge-success">0805 56 44 21</a>
                        ou <a href="tel:01 57 00 57 76" class="badge badge-secondary">01 57 00 57 76</a>
                    </div>
                </div>

                <div class="d-flex mt-1">
                    <div class="w100p">Courrier</div>
                    <div>
                        CSA, Etude Presse<br />
                        2 bis rue Godefroy<br />
                        92800 PUTEAUX<br />
                    </div>
                </div>
            </b-col>
            <b-col sm="3" class="text-right">
                <a href="https://www.gide.net">
                    <img src="../assets/img/logo-gide.png" alt="GIDE.net" />
                </a>
            </b-col>
        </b-row>
    </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
footer {
    font-size: small;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);

    a {
        font-size: inherit;
    }

    .w100p {
        width: 100px;
    }
}
</style>
