<template>
    <MainLayout>
        <h2>{{ user ? user.firstName.toUpperCase() : '' }} {{ user ? user.lastName.toUpperCase() : '' }}</h2>
        <h5>{{ user ? user.email : '' }}</h5>

        <b-row class="mt-5">
            <b-form class="col-lg-5" @submit.prevent="onSubmit">
                <MainTitle
                    title="Données personnelles"
                    text="Si vos coordonnées personnelles (adresse, téléphone fixe, portable, email) changent, merci de prévenir les opérateurs afin de modifier vos données."
                />

                <MainTitle
                    class="mt-5"
                    title="changer de mot de passe"
                    text="Vous avez la possibibilité de changer de mot de passe à tout instant"
                />

                <b-form-group id="password-group" label="Mot de passe actuel" label-for="password">
                    <b-form-input
                        id="password"
                        v-model="oldPassword"
                        type="password"
                        required
                        placeholder="Saisissez votre mot de passe actuel"
                    />
                </b-form-group>

                <b-form-group id="password-group-1" label="Nouveau mot de passe" label-for="password1">
                    <b-form-input
                        id="password1"
                        v-model="password1"
                        type="password"
                        required
                        placeholder="Saisissez votre nouveau mot de passe"
                    />
                </b-form-group>

                <b-form-group id="password-group-2" label="Confirmez mot de passe" label-for="password2">
                    <b-form-input
                        id="password2"
                        v-model="password2"
                        type="password"
                        required
                        placeholder="Reaisissez votre nouveau mot de passe"
                    />
                </b-form-group>

                <div class="d-flex flex-wrap">
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.length > 7 || password2.length > 7"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        8 caractères
                    </div>
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.match(/[A-Z]/) || password2.match(/[A-Z]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 majuscule
                    </div>
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.match(/[a-z]/) || password2.match(/[a-z]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 minuscule
                    </div>
                    <div style="min-width: 80px">
                        <CheckIcon
                            v-if="password1.match(/[0-9]/) || password2.match(/[0-9]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 chiffre
                    </div>
                </div>
            </b-form>
        </b-row>
        <b-col>
            <div class="col-5 my-5 text-right">
                <b-button variant="primary" class="mr-0" :disabled="!isValidPassword" @click="onSubmit">
                    <b-spinner v-if="loading" small />
                    Envoyer
                </b-button>
            </div>
        </b-col>
    </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default {
    components: {
        MainLayout,
        MainTitle,
        CheckIcon,
        XIcon
    },
    data: () => ({
        oldPassword: '',
        password1: '',
        password2: '',
        paneliste: null
    }),
    computed: {
        ...mapGetters({ loading: 'auth/loading', user: 'user/user' }),
        isValidPassword() {
            return (
                this.oldPassword &&
                this.password1 === this.password2 &&
                this.password1.length > 7 &&
                this.password1.match(/[0-9]/) &&
                this.password1.match(/[a-z]/) &&
                this.password1.match(/[A-Z]/) &&
                this.password2.length > 7 &&
                this.password2.match(/[0-9]/) &&
                this.password2.match(/[a-z]/) &&
                this.password2.match(/[A-Z]/)
            )
        }
    },
    methods: {
        ...mapActions({ renew: 'auth/changePassword' }),
        onSubmit() {
            if (this.isValidPassword) {
                this.renew({
                    oldPassword: this.oldPassword,
                    password: this.password1
                }).then(() => {
                    this.oldPassword = ''
                    this.password1 = ''
                    this.password2 = ''
                })
            }
        }
    }
}
</script>
