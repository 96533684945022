<template>
    <PreLayout>
        <MainTitle title="Réinitialisation du mot de passe" text="" />
        <b-row class="justify-content-md-center">
            <b-form class="col-md-7" @submit.prevent="onSubmit">
                <b-form-group label="Nouveau mot de passe" label-for="password1">
                    <b-form-input
                        id="password1"
                        v-model="password1"
                        type="password"
                        required
                        placeholder="Saisissez votre mot de passe"
                    />
                </b-form-group>

                <b-form-group label="Confirmez le mot de passe" label-for="password2">
                    <b-form-input
                        id="password2"
                        v-model="password2"
                        type="password"
                        required
                        placeholder="Re-saisissez votre mot de passe"
                    />
                </b-form-group>

                <div class="d-flex flex-wrap">
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.length > 7 || password2.length > 7"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        8 caractères
                    </div>
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.match(/[A-Z]/) || password2.match(/[A-Z]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 majuscule
                    </div>
                    <div style="min-width: 110px">
                        <CheckIcon
                            v-if="password1.match(/[a-z]/) || password2.match(/[a-z]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 minuscule
                    </div>
                    <div style="min-width: 80px">
                        <CheckIcon
                            v-if="password1.match(/[0-9]/) || password2.match(/[0-9]/)"
                            size="1.5x"
                            class="text-success"
                        />
                        <XIcon v-else size="1.5x" class="text-danger" />
                        1 chiffre
                    </div>
                </div>

                <div class="text-right mt-4">
                    <b-button type="submit" variant="primary" class="mr-0" :disabled="!isValidPassword">
                        <b-spinner v-if="loading" small />
                        Envoyer
                    </b-button>
                </div>
            </b-form>
        </b-row>
    </PreLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PreLayout from '../components/PreLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default {
    components: {
        PreLayout,
        MainTitle,
        CheckIcon,
        XIcon
    },
    data() {
        return {
            password1: '',
            password2: '',
            paneliste: this.$route.params.paneliste,
            validation: this.$route.params.token
        }
    },
    mounted() {},
    computed: {
        ...mapGetters({ loading: 'auth/loading' }),
        isValidPassword() {
            return (
                this.password1 === this.password2 &&
                this.password1.length > 7 &&
                this.password1.match(/[0-9]/) &&
                this.password1.match(/[a-z]/) &&
                this.password1.match(/[A-Z]/) &&
                this.password2.length > 7 &&
                this.password2.match(/[0-9]/) &&
                this.password2.match(/[a-z]/) &&
                this.password2.match(/[A-Z]/)
            )
        }
    },
    methods: {
        ...mapActions({ renew: 'auth/renewPassword' }),
        onSubmit() {
            if (this.isValidPassword) {
                this.renew({
                    password: this.password1,
                    paneliste: this.paneliste,
                    validation: this.validation
                }).then(() => this.$router.push('/login'))
            }
        }
    }
}
</script>
