<template>
    <PreLayout>
        <h3>Mot de passe oublié</h3>
        <p class="mt-4">
            Veuillez renseigner votre adresse mail ou votre numéro de panéliste et cliquer sur envoyer afin de
            réinitialiser votre mot de passe oublié.
        </p>
        <p>
            Sinon appelez la Hotline au <span class="badge badge-success">0805 564 421</span> ou au
            <span class="badge badge-light">01 57 00 57 76</span>.
        </p>
        <b-form @submit.prevent="onSubmit">
            <b-form-input
                v-model="idEmail"
                class="col-sm-9 mt-4"
                type="text"
                required
                placeholder="Email ou N° paneliste"
                @keypress="reset"
            />

            <div class="d-flex justify-content-between mt-4">
                <b-button variant="secondary" :disabled="sent" class="mr-0" @click="() => $router.push('/login')"> Annuler </b-button>
                <b-button v-if="!sent" type="submit" variant="primary" class="mr-0">
                    <b-spinner v-if="loading" small />
                    Envoyer
                </b-button>
                <b-button v-else variant="secondary" class="mr-0" @click="() => $router.push('/login')">
                    Retour
                </b-button>
            </div>
        </b-form>
    </PreLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PreLayout from '../components/PreLayout.vue'

export default {
    components: { PreLayout },
    data: () => ({
        idEmail: '',
        sent: false
    }),
    computed: {
        ...mapGetters({ loading: 'auth/loading', isAuth: 'auth/isAuth' })
    },
    methods: {
        ...mapActions({ send: 'auth/sendResetMail' }),
        onSubmit() {
            this.send(this.idEmail).then(() => {
                this.$notify({
                    type: 'info',
                    text: 'Un email vous a été envoyé avec un lien vous renvoyant à un formulaire de changement de mot de passe',
                    duration: 15000
                })
                this.sent = true
            })
        },
        reset() {
            this.sent = false
        }
    }
}
</script>
