<template>
    <div>
        <b-dropdown class="btn btn-link p-0 notif" variant="link" right no-caret :tabindex="null">
            <template v-slot:button-content>
                <div v-if="count" class="badge badge-primary">{{ count }}</div>
                <BellIcon size="1.3x" />
            </template>
            <div v-if="notifications" class="box-shadow" style="width: 309px;">
                <div v-if="!user.accordParticipationEcrit" class="px-4 py-2">
                    N<span class="text-lowercase"
                        >ous n'avons pas encore reçu votre
                        <a href="http://csa.mesure-presse.fr/files/AccordParticipationPresse.pdf" download
                            ><strong>accord de participation</strong></a
                        >
                        par écrit.</span
                    >
                    P<span class="text-lowercase">ensez à nous le renvoyer !</span>
                </div>

                <b-dropdown-divider v-if="!user.accordParticipationEcrit" />

                <div v-if="!!notifications.relances.length" class="px-4 py-2">
                    <small>
                        S<span class="text-lowercase">
                            auf erreur de notre part, nous n’avons pas enregistré de déclaration pour le titre suivant:
                        </span>
                    </small>
                </div>

                <b-dropdown-item
                    v-for="({ Relance, Titre }, i) in notifications.relances"
                    :key="i"
                    @click="$router.push('/titre/' + Relance.titre_id + (Relance.envoi_relance_id ? '/' + Relance.envoi_relance_id : ''))"
                >
                    <b>{{ Titre.titre }}</b>
                </b-dropdown-item>

                <b-dropdown-divider v-if="!!notifications.relances.length" />

                <b-dropdown-item
                    v-for="({ MessagePaneliste }, i) in notifications.messages"
                    :key="i"
                    @click="deleteNotificationMessage(MessagePaneliste.id)"
                >
                    <small>{{ MessagePaneliste.message }}</small>
                </b-dropdown-item>

                <b-dropdown-item v-if="!!notifications.controles.length" @click="$router.push('/declaration/')">
                    Vous avez 1 titre à re-déclarer. Merci de cliquer ici.
                </b-dropdown-item>

                <b-dropdown-divider v-if="!!notifications.controles.length" />

                <b-dropdown-item v-if="bd && !bdClicked" @click="bdClick">
                    Joyeux anniversaire ! <i class="fa fa-birthday-cake" aria-hidden="true" />
                </b-dropdown-item>
                <b-dropdown-item v-if="!count" class="text-center">
                    <span class="text-capitalize">Aucune</span><span class="text-lowercase"> notification.</span>
                </b-dropdown-item>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { BellIcon } from 'vue-feather-icons'

export default {
    components: { BellIcon },
    data: () => ({
        bdClicked: false
    }),
    computed: {
        ...mapGetters({
            notifications: 'user/notifications',
            user: 'user/user'
        }),
        count() {
            if (this.notifications) {
                const bd = moment(this.notifications.anniversaire, 'DD/MM/YYYY').format('DD/MM')
                const today = moment().format('DD/MM')
                return (
                    this.notifications.controles.length +
                    this.notifications.relances.length +
                    this.notifications.messages.length +
                    (bd === today ? 1 : 0)
                )
            } else return 0
        },
        bd() {
            const bd = moment(this.notifications.anniversaire, 'DD/MM/YYYY').format('DD/MM')
            const today = moment().format('DD/MM')
            return bd === today && this.bdClicked
        }
    },
    created() {
        this.fetchNotifications()
    },
    methods: {
        ...mapActions({
            fetchNotifications: 'user/fetchNotifications',
            deleteNotification: 'user/deleteNotification',
            getTitreNameById: 'courriers/getTitreNameById'
        }),
        bdClick() {
            this.bdClicked = true
        },
        deleteNotificationMessage(id) {
            this.deleteNotification(id)
        }
    }
}
</script>

<style lang="scss" scoped>
.notif {
    position: relative;

    .badge {
        position: absolute;
        top: -1px;
        right: 3px;
        border-radius: 50%;
    }
}
</style>
