import { render, staticRenderFns } from "./Logos.vue?vue&type=template&id=da5c42c2&scoped=true&"
var script = {}
import style0 from "./Logos.vue?vue&type=style&index=0&id=da5c42c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da5c42c2",
  null
  
)

export default component.exports