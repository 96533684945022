<template>
    <MainLayout>
        <MainTitle title="Présentation de la mesure des délais de distribution de la Presse" text="" />
        <p>
            Cette étude permet de suivre et d’améliorer la qualité de distribution de la presse par La Poste. Pour ce
            faire, un panel de récepteurs, positionné sur l’ensemble du territoire métropolitain, est recruté et animé
            par l’institut CSA. Chaque panéliste est abonné à plusieurs titres de presse dont il doit déclarer la
            réception. A partir de ces déclarations, une qualité de service est calculée. Celle-ci représente
            l’indicateur de référence permettant de mesurer l’engagement de La Poste envers les éditeurs de presse.
        </p>
    </MainLayout>
</template>

<script>
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'

export default {
    components: {
        MainLayout,
        MainTitle
    }
}
</script>
