<template>
    <div>
        <div class="background" />
        <router-view />
        <notifications position="bottom right" />
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type.match(/setAlert/g)) {
                const storeName = mutation.type.split('/')[0]
                if (state[storeName].alert !== null) {
                    // https://github.com/euvl/vue-notification#api
                    Vue.notify({
                        title: state[storeName].alert.title || null,
                        text: state[storeName].alert.text || null,
                        duration: state[storeName].alert.duration || 5000,
                        type: state[storeName].alert.type || 'info'
                    })
                }
            }
        })
    }
}
</script>

<style lang="scss">
@import './assets/scss/bootstrap.scss';

html {
    @include media-breakpoint-up(sm) {
        overflow-y: scroll;
    }

    .background {
        position: fixed;
        top: 0;
        clip-path: polygon(0 0, 100% 0, 100% 43%, 0 100%);
        height: 70vh !important;
        width: 100%;
        background-color: $primary;
        z-index: -1;
    }

    .row {
        margin-right: 0 !important;
        margin-left: 0 !important;

        @include media-breakpoint-up(sm) {
            margin-right: -15px !important;
            margin-left: -15px !important;
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .box-shadow {
        border: none !important;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.17);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .btn {
        /*letter-spacing: 0.1rem;*/
        text-transform: uppercase !important;
    }

    .vue-notification {
        border-radius: 6px !important;
        border-left: none;
        font-size: 1rem;
        font-weight: 500;

        .notification-content {
            overflow-wrap: break-word;
        }
    }

    .vdp-datepicker {
        margin-bottom: 0.5em;
        margin-right: 0.5em;

        .datepicker {
            padding: 0.75em 0.5em 0.75em 2.6em;
            border: 1px solid #d7d9e2;
            cursor: pointer;
            background-color: transparent;
            border-radius: 0;
            -webkit-user-select: none; /* Safari 3.1+ */
            z-index: 99;
        }

        @media (max-width: 767px) {
            position: static;
            .vdp-datepicker__calendar {
                position: fixed;
                top: 50%;
                left: 2%;
                width: 96%;
                margin-top: -25%;
                border: none;
                padding: 1em;
                z-index: 2000;
                outline: rgba(0, 0, 0, 0.5) solid 9999px;
            }
        }
    }
}
</style>
