<template>
    <MainLayout :loading="loading" class="titre">
        <MainTitle :title="titre.titre" :text="'Nous sommes le ' + today" />

        <b-row v-if="titre.periodicite === 'Quotidien'" class="mt-2">
            <b-col sm="6" class="mb-4">
                <div class="list-group mb-2">
                    <button
                        v-for="(week, i) in lastWeeks"
                        :key="i"
                        class="list-group-item list-group-item-action"
                        type="button"
                        @click="$router.push(`/quotidien/${titre.id}/${week[0]}`)"
                    >
                        {{ i }}
                    </button>
                </div>
            </b-col>
        </b-row>

        <div v-else>
            <b-row class="mt-5">
                <b-col sm="6" class="mb-4">
                    <h4>J'ai reçu un exemplaire</h4>
                    <form @submit.prevent="declare">
                        <div class="input-group mb-3 mt-3" style="max-width: 250px">
                            <b-form-input v-model="numero" placeholder="Numéro" />
                            <b-input-group-append>
                                <button id="mag-number" class="btn btn-primary" type="submit">Valider</button>
                            </b-input-group-append>
                        </div>
                    </form>
                </b-col>
                <b-col sm="6">
                    <h4>Je n'ai pas reçu</h4>
                    <p>Je n'ai pas reçu mon numéro{{ titre.parution_hebdo ? ' de ' + moment().day(+titre.parution_hebdo).format('dddd') : '' + '.'}}.</p>
                    <button class="btn btn-danger" @click="signal">Signaler</button>
                    <p v-if="titre.nr" class="mt-2 text-danger">
                        Nous avons bien enregistré un signalement le {{ titre.nr.created_date }},
                        <a class="cursor-pointer" @click="cancelNR"><b>cliquez ici</b> pour annuler</a>
                    </p>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col>
                    <h4>Mes anciennes déclarations</h4>
                    <table v-if="titre.old && titre.old.length > 0" class="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Reçu le</th>
                                <th scope="col">Date imprimée</th>
                                <th class="d-none d-md-block d-lg-block" scope="col">Distributeur</th>
                                <th scope="col"><span class="d-none d-sm-inline">Correction / Annulation</span><span class="d-inline d-sm-none">Actions</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(old, i) in titre.old" :key="i">
                                <th scope="row">{{ old.numero }}</th>
                                <td>{{ old.date_reception }}</td> <!-- | formatDateFromDB -->
                                <td>{{ old.date_imprimee }}</td>
                                <td class="d-none d-md-block d-ls-block">
                                    {{ old.distribue_laposte ? 'La Poste' : 'Autre' }}
                                </td>
                                <td class="d-flex d-sm-table-cell justify-content-between">
                                    <button
                                        v-if="old.modifiable"
                                        class="btn btn-white py-0 p-0 mr-sm-5"
                                        @click="() => edit(old)"
                                    >
                                        <Edit3Icon size="1.3x" class="text-secondary" />
                                    </button>
                                    <button
                                        v-if="old.modifiable"
                                        class="btn btn-white py-0 p-0"
                                        @click="() => remove(old.numero)"
                                    >
                                        <Trash2Icon size="1.3x" class="mt-n1 text-danger" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else>Aucune ancienne declaration</p>
                    <button class="btn btn-secondary my-5" @click="() => $router.push('/declarer-titres')">Retour</button>
                </b-col>
            </b-row>
        </div>
    </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import { Edit3Icon, Trash2Icon } from 'vue-feather-icons'

export default {
    components: {
        MainLayout,
        MainTitle,
        Edit3Icon,
        Trash2Icon
    },
    data() {
        return {
            today: moment().format('dddd D MMMM YYYY'),
            titreId: this.$route.params.titreId,
            relId: this.$route.params.envoiRelanceId,
            numero: null,
            lastWeeks: {},
            moment
        }
    },
    created() {
        if (this.titreId) {
            // noinspection JSCheckFunctionSignatures
            this.getTitreById(this.titreId)
        }

        // Quotidiens
        const week1 = `Semaine du ${moment().weekday(0).format('D MMMM')} au ${moment().weekday(6).format('D MMMM')}`
        this.lastWeeks[week1] = []
        for (let i = 0; i <= moment().weekday(); i++) {
            this.lastWeeks[week1].push(moment().weekday(i))
        }

        const week2 = `Semaine du ${moment().weekday(-7).format('D MMMM')} au ${moment().weekday(-1).format('D MMMM')}`
        this.lastWeeks[week2] = []
        for (let i = -7; i < 0; i++) {
            this.lastWeeks[week2].push(moment().weekday(i))
        }

        const week3 = `Semaine du ${moment().weekday(-14).format('D MMMM')} au ${moment().weekday(-8).format('D MMMM')}`
        this.lastWeeks[week3] = []
        for (let i = -14; i < -7; i++) {
            this.lastWeeks[week3].push(moment().weekday(i))
        }
    },
    computed: mapGetters({
        loading: 'courriers/loading',
        titre: 'courriers/titre'
    }),
    filters: {
        formatDateFromDB(date) {
            return date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'
        }
    },
    methods: {
        ...mapActions({
            getTitreById: 'courriers/getTitreById',
            cancel: 'courriers/cancel',
            declareNonRecu: 'courriers/declareNonRecu',
            cancelNonRecu: 'courriers/cancelNonRecu'
        }),
        declare() {
            if (this.numero) this.$router.push(`/declaration/${this.titreId}/numero/${this.numero}/${this.relId || ''}`)
        },
        edit(titre) {
            this.$router.push('/declaration/' + this.titreId + '/numero/' + titre.numero)
        },
        remove(numero) {
            this.cancel({ titreId: this.titreId, numero })
        },
        signal() {
            this.declareNonRecu(this.titreId)
        },
        cancelNR() {
            this.cancelNonRecu({ id: this.titre.nr.id, titreId: this.titreId })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';

.titre {
    .courrier-card {
        border: none;
        border-radius: $border-radius;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.17);

        .card-body {
            border-left: 20px solid $secondary !important;
            border-radius: $border-radius;
        }
    }
}
</style>
