import Vue from 'vue'
import Router from 'vue-router'
import Accueil from './views/Accueil.vue'
import store from './store'
import axios from 'axios'
import DeclarerTitres from './views/DeclarerTitres'
import Login from './views/Login'
import ResetPW from './views/ResetPW'
import RenewPW from './views/RenewPW'
import Accord from './views/Accord'
import Validation from './views/Validation'
import Titre from './views/Titre'
import Quotidien from './views/Quotidien'
import Declaration from './views/Declaration'
import Absences from './views/Absences'
import NewsLetters from './views/NewsLetters'
import FAQ from './views/FAQ'
import Profil from './views/Profil'
import APropos from './views/APropos'
import E404 from './views/404'

// https://github.com/axios/axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8000'

Vue.use(Router)

const router = new Router({
    mode: 'history', // or 'hash'
    routes: [
        {
            path: '/',
            name: 'accueil',
            component: Accueil,
            meta: {
                auth: true
            }
        },
        {
            path: '/declarer-titres',
            name: 'declarer-titres',
            component: DeclarerTitres,
            meta: {
                auth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/reset-pw',
            name: 'reset-pw',
            component: ResetPW
        },
        {
            path: '/renew-pw/p/:paneliste/t/:token',
            name: 'renew-pw',
            component: RenewPW
        },
        {
            path: '/accord',
            name: 'accord',
            component: Accord,
            meta: {
                auth: true
            }
        },
        {
            path: '/validation',
            name: 'validation',
            component: Validation,
            meta: {
                auth: true
            }
        },
        {
            path: '/titre/:titreId/:envoiRelanceId?',
            name: 'titre',
            component: Titre,
            meta: {
                auth: true
            }
        },
        {
            path: '/quotidien/:titreId/:semaine/:envoiRelanceId?',
            name: 'quotidien',
            component: Quotidien,
            meta: {
                auth: true
            }
        },
        {
            path: '/declaration/:titreId/numero/:numero/:envoiRelanceId?',
            name: 'declaration',
            component: Declaration,
            meta: {
                auth: true
            }
        },
        {
            path: '/absences',
            name: 'absences',
            component: Absences,
            meta: {
                auth: true
            }
        },
        {
            path: '/newsletters',
            name: 'newsletters',
            component: NewsLetters,
            meta: {
                auth: true
            }
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQ,
            meta: {
                auth: true
            }
        },
        {
            path: '/profil',
            name: 'profil',
            component: Profil,
            meta: {
                auth: true
            }
        },
        {
            path: '/a-propos',
            name: 'a-propos',
            component: APropos,
            meta: {
                auth: true
            }
        },
        {
            path: '/*',
            component: E404
        }
    ]
})

store.dispatch('auth/initAuth').then(() => {
    const user = store.getters['user/user']

    if (router.history.current.name !== 'accord' && user && !user.accordParticipation)
        router.push('accord').catch(console.log)

    if (router.history.current.name === 'accord' && user && user.accordParticipation)
        router.push('/').catch(console.log)

    if (router.history.current.name !== 'validation' && user && !user.validationDonnees)
        router.push('validation').catch(console.log)

    if (router.history.current.name === 'validation' && user && user.validationDonnees)
        router.push('/').catch(console.log)
})

router.beforeEach((to, from, next) => {
    const isAuth = store.getters['auth/isAuth']
    const user = store.getters['user/user']

    if (to.matched.some(route => route.meta.auth)) {
        if (!isAuth) next({ name: 'login' })
        else if (to.name !== 'accord' && user && !user.accordParticipation) next({ name: 'accord' })
        else if (to.name === 'accord' && user && !user.accordParticipation) next({ name: to.meta.name })
        else if (to.name === 'accord' && user && user.accordParticipation) next({ name: 'accueil' })
        else if (to.name !== 'validation' && user && user.accordParticipation && !user.validationDonnees) next({ name: 'validation' })
        else if (from.name === 'validation' && user && user.accordParticipation && !user.validationDonnees) next({ name: to.meta.name })
        else if (to.name === 'validation' && user && user.validationDonnees) next({ name: 'accueil' })
        else next({ name: to.meta.name })
    } else next({ name: to.meta.name })
})

export default router
