<template>
    <header>
        <div id="nav-mobile-menu" :class="`bg-primary d-block d-lg-none ${isNavMobile ? 'show' : ''}`">
            <div class="bg-dark py-5 cursor-pointer" @click="$router.push('/')">
                <h1 class="px-5 text-white">QSP</h1>
            </div>
            <b-navbar-nav class="m-0">
                <router-link to="/" class="nav-link px-5 py-3 text-uppercase">
                    Accueil
                </router-link>
                <router-link to="/declarer-titres" class="nav-link px-5 py-3 text-uppercase">
                    Déclarer vos titres
                </router-link>
                <router-link to="/absences" class="nav-link px-5 py-3 text-uppercase">
                    Absences
                </router-link>
                <a
                    href="https://legroupe.laposte.fr/actualite"
                    target="_blank"
                    class="nav-link px-5 py-3 text-uppercase"
                >
                    La Poste Actualité
                </a>
                <router-link to="/newsletters" class="nav-link px-5 py-3 text-uppercase">
                    NewsLetter
                </router-link>
                <router-link to="/faq" class="nav-link px-5 py-3 text-uppercase">
                    FAQ
                </router-link>
            </b-navbar-nav>
        </div>

        <div
            id="nav-mobile-overlay"
            :class="`d-block d-lg-none ${isNavMobile ? 'show' : ''}`"
            @click="toggleMobileMenu"
        ></div>

        <b-navbar toggleable="lg" type="light" variant="white" fixed="top">
            <b-container>
                <b-navbar-toggle target="_blank" @click="toggleMobileMenu" />

                <b-navbar-nav class="top d-none d-lg-flex">
                    <b-nav-item>
                        <router-link to="/" class="nav-link top text-uppercase">
                            Accueil
                        </router-link>
                    </b-nav-item>
                    <b-nav-item>
                        <router-link to="/declarer-titres" class="nav-link top text-uppercase">
                            Déclarer vos titres
                        </router-link>
                    </b-nav-item>
                    <b-nav-item>
                        <router-link to="/absences" class="nav-link top text-uppercase">
                            Absences
                        </router-link>
                    </b-nav-item>
                    <li class="nav-item">
                        <span class="nav-link">
                            <a
                                href="https://legroupe.laposte.fr/actualite"
                                target="_blank"
                                class="nav-link top text-uppercase"
                            >
                                La Poste Actualité
                            </a>
                        </span>
                    </li>
                    <b-nav-item>
                        <router-link to="/newsletters" class="nav-link top text-uppercase">
                            NewsLetter
                        </router-link>
                    </b-nav-item>
                    <b-nav-item>
                        <router-link to="/faq" class="nav-link top text-uppercase">
                            FAQ
                        </router-link>
                    </b-nav-item>
                </b-navbar-nav>

                <div class="d-flex ml-auto">
                    <NotificationButton class="mr-4" />
                    <b-dropdown :text="userInitals" class="btn-link user-menu-round" variant="link" right>
                        <div class="box-shadow">
                            <b-dropdown-item @click="$router.push('profil')">
                                <UserIcon size="1x" class="mb-1 ml-n1 mr-1" /> Profil
                            </b-dropdown-item>
                            <b-dropdown-item @click="logout">
                                <LogOutIcon size="1x" class="mb-1 ml-n1 mr-1" /> Déconnexion
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                </div>
            </b-container>
        </b-navbar>
    </header>
</template>

<script>
import NotificationButton from './NotificationButton'
import { UserIcon, LogOutIcon } from 'vue-feather-icons'

export default {
    components: {
        NotificationButton,
        UserIcon,
        LogOutIcon
    },
    data: () => ({
        isNavMobile: false
    }),
    props: {
        userInitals: String,
        logout: Function,
        isAuth: Boolean,
        notifications: Object
    },
    methods: {
        toggleMobileMenu() {
            this.isNavMobile = !this.isNavMobile
        }
    }
}
</script>

<style>
.dropdown-menu {
    margin: 0 !important;
    padding: 0 !important;
}
.dropdown-item {
    padding: 0.5rem 1.5rem !important;
}
</style>

<style lang="scss" scoped>
header {
    .router-link-exact-active {
        border-bottom: 4px solid #fecb00;
    }

    .navbar {
        width: 100vw;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
        z-index: 100;

        .top .nav-link {
            height: 53px;
            font-weight: 500;
            font-family: 'Source Sans Pro', sans-serif;
            // letter-spacing: 0.1rem;
        }

        .router-link-exact-active {
            border-bottom: 4px solid #fecb00;
        }

        .navbar-toggler {
            border: none;
        }
    }

    #nav-mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
        transition: opacity 200ms ease;
        z-index: -1;

        &.show {
            opacity: 1;
            z-index: 1000;
        }

        .router-link-exact-active {
            position: relative;
            border-bottom: none;
            background-color: #ffdd59;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 56px;
                border-left: 5px solid #fff;
            }
        }
    }

    #nav-mobile-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0;
        transition: opacity 200ms ease;
        z-index: -1;

        &.show {
            opacity: 0.5;
            z-index: 999;
        }
    }

    .btn.dropdown-toggle.btn-link {
        font-size: small !important;
    }

    .user-menu-round {
        position: relative;
        color: #212529;

        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: 4px;
            width: 3em;
            height: 3em;
            border-radius: 50%;
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }

        &:hover {
            &::before {
                color: #212529;
                background-color: #e2e6ea;
                border-color: #dae0e5;
            }
        }
    }
}
</style>

<style lang="scss">
header {
    .dropdown-menu {
        border: none;

        &:focus {
            outline: none;
        }

        .dropdown-item {
            white-space: unset;
        }
    }

    .user-menu-round {
        .btn {
            font-size: small !important;
            margin-top: 2px;
        }
    }
}
</style>
