<template>
    <MainLayout :loading="loading" class="declaration">
        <MainTitle :title="'N°' + numero + ' / ' + titre.titre" :text="'Nous sommes le ' + today" />

        <div v-if="action === 'inconnu'">
            <p>
                Pour nous <strong>{{ numero }}</strong> n'est pas un numéro du titre
                <strong>{{ titre.titre }}</strong> ou est un numéro peu plausible.<br />
                Veuillez vérifier que le numéro n'est pas erroné. Si vous êtes certain que c'est bien le numéro du
                titre, cliquez sur "Continuer".<br />Si vous avez un doute, n'hésitez pas à prendre contact avec nous.<br />
            </p>
            <div class="row justify-content-between mt-4 px-3">
                <button type="button" class="btn btn-secondary mt-4" @click="$router.go(-1)">Retour</button>
                <button type="button" class="btn btn-primary mt-4" @click="confirm">Continuer</button>
            </div>
        </div>

        <div v-if="action === 'refus'">
            <p>
                Il n'est plus possible d'enregistrer ou de modifier la déclaration pour le numéro
                <strong>{{ numero }}</strong> du titre <strong> {{ titre.titre }}.</strong><br />
                Veuillez nous contacter si cela ne vous semble pas normal.<br />
            </p>
            <button type="button" class="btn btn-secondary mt-4" @click="$router.go(-1)">Retour</button>
        </div>

        <div v-if="action === 'cmodif'">
            <p>
                Vous avez déjà déclaré le
                <strong>{{ editedCreated ? editedCreated.substr(0, 10) : '' }}</strong> les informations pour le numéro
                <strong>{{ numero }}</strong> du titre <strong>{{ titre.titre }}.</strong><br />
                Pour modifier la déclaration du numéro {{ numero }}, cliquez sur "Continuer".
            </p>

            <div class="row justify-content-between mt-4 px-3">
                <button type="button" class="btn btn-secondary mt-4" @click="$router.go(-1)">Retour</button>
                <button type="button" class="btn btn-primary mt-4" @click="confirm">Continuer</button>
            </div>
        </div>

        <form v-if="action === 'exemplaire'" @submit.prevent="onSubmit">
            <div class="form-group row mt-5">
                <label for="dateReception" class="col-md-3 col-form-label">
                    Date de réception <span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <Datepicker
                        id="dateReception"
                        v-model="dateReception"
                        format="dd/MM/yyyy"
                        :monday-first="true"
                        :language="fr"
                        :disabled-dates="{ from: new Date() }"
                        placeholder="Date de reception"
                        :bootstrap-styling="true"
                        input-class="datepicker"
                        @input="onChange('dateReception')"
                    >
                        <span slot="afterDateInput" class="datepicker-icon">
                            <CalendarIcon size="1.1x" class="text-primary" />
                        </span>
                    </Datepicker>
                    <div class="mt-3">
                        <b-form-checkbox v-model="noDateReception" @change="onChange('noDateReception')">
                            Je ne suis pas certain(e) de la date de réception.
                        </b-form-checkbox>
                    </div>
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="dateImprimee" class="col-md-3 col-form-label">
                    Date imprimée <span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <Datepicker
                        id="dateImprimee"
                        v-model="dateImprimee"
                        format="dd/MM/yyyy"
                        :monday-first="true"
                        :language="fr"
                        :disabled-dates="{ from: new Date() }"
                        placeholder="Date imprimée"
                        :bootstrap-styling="true"
                        input-class="datepicker"
                        @input="onChange('dateImprimee')"
                    >
                        <span slot="afterDateInput" class="datepicker-icon">
                            <CalendarIcon size="1.1x" class="text-primary" />
                        </span>
                    </Datepicker>
                    <div class="mt-3">
                        <b-form-checkbox v-model="noDateImprimee" @change="onChange('noDateImprimee')">
                            La date imprimée est illisible ou absente.
                        </b-form-checkbox>
                    </div>
                </div>
            </div>

            <fieldset class="form-group mt-5">
                <div class="row">
                    <legend class="col-form-label col-md-3 pt-0">
                        Distribué par <u>La Poste</u> avec ce logo/mention <span class="text-danger">*</span>
                    </legend>
                    <div class="col-md-9">
                        <b-form-group>
                            <b-form-radio v-model="distribution" value="1">Oui</b-form-radio>
                            <b-form-radio v-model="distribution" value="0">Non</b-form-radio>
                        </b-form-group>
                        <b-form-checkbox v-if="distribution === '0'" v-model="distributionConfirmation">
                            Confirmez-vous que cet exemplaire n’a pas été distribué par La Poste ?
                        </b-form-checkbox>
                    </div>
                </div>
            </fieldset>

            <div class="form-group row mt-5">
                <div class="col-md-4">
                    Les titres distribués par La Poste sont souvent sous film plastique et identifiables grâce au logo
                    suivant ou à la mention <strong>PRESSE DISTRIBUÉE PAR LA POSTE</strong>.
                </div>
                <div class="col-md-8">
                    <img :src="getLogo1" class="mt-4 mt-md-0" alt="" />
                    <img :src="getLogo2" class="mt-4 mt-md-0 ml-md-5" alt="" />
                </div>
            </div>

            <div class="row justify-content-between my-4 px-3">
                <button type="button" class="btn btn-secondary mt-4" @click="$router.go(-1)">Retour</button>
                <button
                    v-b-tooltip.hover
                    type="submit"
                    class="btn btn-primary mt-4"
                    :title="isValidForm ? '' : 'Veuillez remplir tous les champs requis.'"
                    @click="confirm"
                >
                    <span v-if="submitLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                    Valider
                </button>
            </div>
        </form>
    </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-ferie-fr'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'
import Datepicker from 'vuejs-datepicker'
import { CalendarIcon } from 'vue-feather-icons'
import fr from 'vuejs-datepicker/dist/locale/translations/fr'

export default {
    components: {
        MainLayout,
        MainTitle,
        Datepicker,
        CalendarIcon
    },
    data() {
        return {
            fr,
            today: moment().format('dddd D MMMM YYYY'),
            titreId: this.$route.params.titreId,
            numero: this.$route.params.numero,
            envoiRelanceId: this.$route.params.envoiRelanceId,
            action: null,
            editedId: null,
            editedCreated: null,
            dateReception: null,
            dateImprimee: null,
            noDateReception: false,
            noDateImprimee: false,
            distribution: '',
            noDistribution: false,
            distributionConfirmation: false
        }
    },
    created() {
        if (this.titreId && this.numero) {
            this.checkNumero({ id: this.titreId, numero: this.numero }).then(action => (this.action = action))
            // noinspection JSCheckFunctionSignatures
            this.getTitreById(this.titreId).then(() => {
                if (this.action === 'cmodif') {
                    const declaration = this.titre.old.filter(old => old.numero === this.numero)[0]
                    if (declaration) {
                        this.editedId = declaration.id
                        this.editedCreated = moment(declaration.created, 'DD/MM/YYYY').format('DD/MM/YYYY')
                        this.dateReception = declaration.date_reception
                            ? moment(declaration.date_reception, 'DD/MM/YYYY').toDate()
                            : null
                        this.dateImprimee = declaration.date_imprimee
                            ? moment(declaration.date_imprimee, 'DD/MM/YYYY').toDate()
                            : null
                        this.noDateReception = declaration.date_reception_incertaine
                        this.noDateImprimee = declaration.date_imprimee_incertaine
                        this.distribution = declaration.distribue_laposte ? '1' : '0'
                    }
                }
            })
        } else this.action = 'inconnu'
    },
    computed: {
        ...mapGetters({
            loading: 'courriers/loading',
            submitLoading: 'courriers/submitLoading',
            titre: 'courriers/titre',
            user: 'user/fullUser'
        }),
        isValidForm() {
            return (
                (this.dateReception || this.noDateReception) &&
                (this.dateImprimee || this.noDateImprimee) &&
                (this.distribution === '1' || this.distributionConfirmation)
            )
        },
        getLogo1() {
            switch (this.titre['categorie_presse_id']) {
                case 'PNU':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_logo_0_140px.jpg'
                case 'PMU':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_urgente_logo_0_140px.jpg'
                case 'PMU2':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P2_140px.jpg'
                case 'PECO':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_eco_logo_0_140px.jpg'
                case 'PQA':
                    return process.env.VUE_APP_BO_URL + '/img/Presse_urgente_logo_0_140px.jpg'
                default:
                    return process.env.VUE_APP_BO_URL + '/img/Presse_logo_0_140px.jpg'
            }
        },
        getLogo2() {
            switch (this.titre['categorie_presse_id']) {
                case 'PNU':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P4_140px.jpg'
                case 'PMU':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P1_140px.jpg'
                case 'PECO':
                    return process.env.VUE_APP_BO_URL + '/img/logo_P7_140px.jpg'
                default:
                    return ''
            }
        }
    },
    methods: {
        ...mapActions({
            getTitreById: 'courriers/getTitreById',
            checkNumero: 'courriers/checkNumero',
            declareOrEdit: 'courriers/declareOrEdit'
        }),
        onChange(id) {
            const pre = id.substr(0, 2)
            const post = id.substr(2)
            const input = post.charAt(0).toLowerCase() + post.slice(1)

            if (pre === 'no') this[input] = null
            else this['no' + id.charAt(0).toUpperCase() + id.slice(1)] = false
        },
        confirm() {
            this.action = 'exemplaire'
        },
        onSubmit() {
            // Refacto possible
            if (!this.isValidForm) return null
            else if (
                this.dateReception &&
                this.dateImprimee &&
                (moment(this.dateReception) > moment() || moment(this.dateImprimee) > moment())
            ) {
                window.scrollTo(0, 120)
                this.$notify({
                    duration: 8000,
                    type: 'info',
                    text: `Les dates ne peuvent pas être supérieure à la date d'aujourd'hui.`
                })
            } else if (
                this.dateReception &&
                (moment(this.dateReception).isFerie(this.user.departement) || moment(this.dateReception).format('dddd') === 'Sunday' || moment(this.dateReception).format('dddd') === 'dimanche')
            ) {
                window.scrollTo(0, 120)
                this.$notify({
                    duration: 8000,
                    type: 'info',
                    text: 'La date de réception ne peut pas être dimanche ou jour férié.'
                })
            } else if (this.dateReception && this.dateImprimee && moment(this.dateReception).isBefore(moment(this.dateImprimee))) {
                window.scrollTo(0, 120)
                this.$notify({
                    duration: 8000,
                    type: 'info',
                    text: 'La date imprimée doit être inférieure ou égale à la date de réception.'
                })
            } else if (!this.dateImprimee && !this.noDateImprimee) {
                window.scrollTo(0, 120)
                this.$notify({
                    duration: 8000,
                    type: 'info',
                    text: 'Veuillez saisir une date ou cocher la case "La date imprimée est illisible ou absente".'
                })
            } else if (!this.dateReception && !this.noDateReception) {
                window.scrollTo(0, 120)
                this.$notify({
                    duration: 8000,
                    type: 'info',
                    text:
                        'Veuillez saisir une date ou cocher la case "Je ne suis pas certain(e) de la date de réception".'
                })
            } else {
                const data = {
                    ancienne_declaration_id: this.editedId ? +this.editedId : null,
                    titre_id: this.titreId,
                    numero: this.numero,
                    date_reception_incertaine: this.noDateReception,
                    date_imprimee_incertaine: this.noDateImprimee,
                    distribue_laposte: this.distribution === '1'
                }
                if (this.envoiRelanceId) data.envoiRelance = this.envoiRelanceId

                // Pour satisfaire la validation coté CakePHP
                if (!this.noDateReception) data['date_reception'] = moment(this.dateReception).format('DD/MM/YYYY')
                if (!this.noDateImprimee) data['date_imprimee'] = moment(this.dateImprimee).format('DD/MM/YYYY')

                this.declareOrEdit(data).then(() => {
                    this.$router.push('/titre/' + this.titreId)
                })
            }
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/bootstrap';

.declaration {
    a.btn.btn-link {
        letter-spacing: 0;
        text-transform: lowercase;
    }

    .vdp-datepicker {
        max-width: 185px;

        .datepicker-icon {
            position: absolute;
            top: 6px;
            left: 18px;
        }

        .datepicker {
            background: transparent;
            border-radius: $border-radius !important;
            padding-left: 50px !important;
        }

        .vdp-datepicker__calendar {
            z-index: 101;
        }
    }
}
</style>
