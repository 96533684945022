import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import content from './content'
import courriers from './courriers'
import absences from './absences'

Vue.use(Vuex)

// https://vuex.vuejs.org
export default new Vuex.Store({
    strict: true,
    modules: {
        auth,
        user,
        content,
        courriers,
        absences
    }
})
