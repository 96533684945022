import axios from 'axios'
import moment from 'moment'

const absences = {
    namespaced: true,
    state: () => ({
        absences: [],
        indisponibilities: {
            lun: { '10-12': false, '12-14': false, '14-18': false, '18-20': false },
            mar: { '10-12': false, '12-14': false, '14-18': false, '18-20': false },
            mer: { '10-12': false, '12-14': false, '14-18': false, '18-20': false },
            jeu: { '10-12': false, '12-14': false, '14-18': false, '18-20': false },
            ven: { '10-12': false, '12-14': false, '14-18': false, '18-20': false },
            sam: { '10-12': false, '12-14': false, '14-18': false },
        },
        loading: false,
        submitLoading: false,
        alert: null
    }),
    getters: {
        absences: state => state.absences,
        indisponibilities: state => state.indisponibilities,
        loading: state => state.loading,
        submitLoading: state => state.submitLoading,
        alert: state => state.alert
    },
    mutations: {
        setAbsences: (state, payload) => (state.absences = payload.map(a => a.Absence)),
        setIndisponibilities: (state, payload) => {
            state.indisponibilities = { ...state.indisponibilities, ...payload }
        },
        setLoading: (state, payload) => (state.loading = payload),
        setSubmitLoading: (state, payload) => (state.submitLoading = payload),
        setAlert: (state, payload) => (state.alert = payload)
    },
    actions: {
        /**
         * Récupère les absences
         * @param commit
         * @param fetch
         * @returns {Promise<AxiosResponse>}
         */
        fetchAbsences({ commit }, fetch = true) {
            if (fetch) commit('setLoading', true)
            return axios
                .get('/absences/absences')
                .then(({ data }) => {
                    commit('setLoading', false)
                    commit('setAbsences', data)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        },
        /**
         * Add
         * @param commit
         * @param dispatch
         * @param state
         * @param payload
         * @returns {Promise}
         */
        addAbsence({ commit, dispatch, state }, payload) {
            if (payload.debut === 'Invalid date' || payload.fin === 'Invalid date') {
                commit('setAlert', {
                    text: 'Veuillez sélectionner des dates avant de cliquer sur le bouton "Ajouter',
                    type: 'info'
                })
                return Promise.resolve()
            }

            const start = moment(payload.debut, 'DD/MM/YYYY')
            const end = moment(payload.fin, 'DD/MM/YYYY')

            if (start > end) {
                commit('setAlert', {
                    text: 'La date de début doit être supérieure à la date de fin',
                    type: 'info'
                })
                return Promise.resolve()
            }

            let overlap = false
            let absences = null

            if (payload.id) {
                if (start.isBefore(moment())) {
                    commit('setAlert', {
                        text: "Vous ne pouvez pas modifier la date de début d'une absence en cours !",
                        type: 'info'
                    })
                    return Promise.resolve()
                }
                absences = state.absences.filter(absence => absence.id !== payload.id)
            } else {
                absences = state.absences
                delete payload.id
            }

            for (const absence of absences) {
                if (
                    (start < moment(absence.debut, 'DD/MM/YYYY') && end > moment(absence.debut, 'DD/MM/YYYY')) ||
                    (start < moment(absence.fin, 'DD/MM/YYYY') && start > moment(absence.fin, 'DD/MM/YYYY')) ||
                    (start < moment(absence.debut, 'DD/MM/YYYY') && end > moment(absence.fin, 'DD/MM/YYYY'))
                ) {
                    overlap = true
                }
            }

            if (overlap) {
                commit('setAlert', {
                    text: "Ajout d'absence impossible sur cette période !",
                    type: 'info'
                })
                return Promise.resolve()
            }

            if (start.diff(end, 'days') >= 28 || !lessThan4WeeksRec(start, end, absences, 0)) {
                commit('setAlert', {
                    text:
                        'Votre déclaration ne peut être prise en compte, vous ne pouvez pas déclarer 4 semaines consécutives de vacances. Merci de prendre contact avec la hotline pour déclarer vos congés.',
                    type: 'info',
                    duration: 8000
                })
                return Promise.resolve()
            }
            commit('setSubmitLoading', true)
            return axios
                .post('/absences/addAbsence', payload)
                .then(({ data }) => {
                    dispatch('fetchAbsences', false).then(() => {
                        commit('setAlert', {
                            text:
                                payload.suspendreQuotidiens === data.Absence.suspendre_quotidiens
                                    ? "Merci d'avoir déclaré votre absence ! Déclarez les titres reçus pendant votre absence à votre retour."
                                    : "Merci d'avoir déclaré votre absence. Les critères pour la suspension ne sont pas remplis, la suspension ne pourra donc pas avoir lieu.",
                            type: 'success',
                            duration: 8000
                        })

                        // if (data && moment().diff(start, 'days') <= 21) {
                        //     commit('setAlert', {
                        //         title:
                        //             'Vos congés sont proches, vous risquez de recevoir peut être quelques courriers pendant cette période.',
                        //         text:
                        //             'Dans ce cas, n’oubliez pas de déclarer à votre retour la date de cachet, le distributeur et la mention.',
                        //         type: 'info',
                        //         duration: 10000
                        //     })
                        // }

                        commit('setSubmitLoading', false)
                    })
                })
                .catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    commit('setSubmitLoading', false)
                })
        },
        /**
         * Remove
         * @param commit
         * @param dispatch
         * @param id
         * @returns {Promise}
         */
        deleteAbsence({ commit, dispatch }, id) {
            return axios
                .post('/absences/deleteAbsence', { id })
                .then(() => {
                    dispatch('fetchAbsences', false)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        },
        /**
         * Fetch
         * @param commit
         * @param loader
         * @returns {Promise}
         */
        fetchIndisponibilities({ commit }, loader = true) {
            if (loader) commit('setLoading', true)
            return axios
                .get('/absences/indisponibilities')
                .then(({ data }) => {
                    commit('setLoading', false)
                    commit('setIndisponibilities', data)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        },
        /**
         * Toggle
         * @param commit
         * @param dispatch
         * @param state
         * @param payload
         * @returns {Promise}
         */
        toggleIndisponibilities({ commit, dispatch, state }, payload) {
            const indispos = {
                ...state.indisponibilities,
                [payload.day]: {
                    ...state.indisponibilities[payload.day],
                    [payload.indispo]: !state.indisponibilities[payload.day][payload.indispo]
                }
            }

            if (
                !Object.values(indispos)
                    .map(o => Object.values(o))
                    .flat()
                    .includes(false)
            ) {
                commit('setAlert', { text: 'Il faut que nous ayons au moins une période pour vous joindre ! ' })

                return Promise.resolve()
            }

            return axios
                .post('/absences/toggleIndisponibilitie', indispos)
                .then(() => {
                    return dispatch('fetchIndisponibilities', false)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        }
    }
}

// Code legacy MCI //
const isConsecutiveDates = (date1, date2) => Math.ceil(Math.abs(date1 - date2) / (1000 * 3600 * 24)) <= 1

function lessThan4WeeksRec(beginDate, endDate, arrDates, cumPeriod) {
    const currentPeriod = Math.ceil(Math.abs(endDate - beginDate) / (1000 * 3600 * 24)) + 1

    if (currentPeriod < 28) {
        cumPeriod += currentPeriod

        if (cumPeriod < 28) {
            if (arrDates.length < 1) return true

            const copyArrDates = [...arrDates]

            for (const dates of arrDates) {
                if (
                    isConsecutiveDates(moment(dates.fin, 'DD/MM/YYYY'), beginDate) ||
                    isConsecutiveDates(moment(dates.debut, 'DD/MM/YYYY'), endDate)
                ) {
                    const remIndex = copyArrDates.indexOf(dates)

                    if (remIndex > -1) copyArrDates.splice(remIndex, 1)

                    const res = lessThan4WeeksRec(
                        moment(dates.debut, 'DD/MM/YYYY'),
                        moment(dates.fin, 'DD/MM/YYYY'),
                        copyArrDates,
                        cumPeriod
                    )

                    if (!res) return false

                    cumPeriod = res
                    if (cumPeriod > 28) return false
                }
            }

            return cumPeriod < 28 ? cumPeriod : false
        }
    }
    return false
}
// fin code legacy //

export default absences
