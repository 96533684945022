<template>
    <MainLayout :loading="loading">
        <h1 class="text-uppercase">
            bonjour <span v-if="user" class="text-primary">{{ user.firstName }} {{ user.lastName }}</span>
        </h1>
        <b-row>
            <div class="col-12" v-html="welcomeMessage"></div>
            <b-col md="6">
                <MainTitle
                    :title="`Déclarer <span class='d-none d-lg-inline'>vos titres de presse</span>`"
                    text="Pour déclarer la réception ou la non réception, cliquez sur le titre."
                    class="mt-4"
                />

                <div v-if="!courrierLoading" class="magazines mt-4">
                    <div v-if="quotidiens.length > 0" class="list-group mb-2">
                        <button type="button" class="list-group-item list-group-item-action active py-1">
                            Tous les jours
                        </button>
                        <button
                            v-for="(mag, i) in quotidiens"
                            :key="i"
                            class="list-group-item list-group-item-action"
                            type="button"
                            @click="$router.push('/titre/' + mag.id)"
                        >
                            {{ mag.title }}
                        </button>
                    </div>

                    <div v-if="biHebdomadaires.length > 0" class="list-group mb-2">
                        <button type="button" class="list-group-item list-group-item-action active py-1">
                            Deux fois par semaine
                        </button>
                        <button
                            v-for="(mag, i) in biHebdomadaires"
                            :key="i"
                            class="list-group-item list-group-item-action"
                            type="button"
                            @click="$router.push('/titre/' + mag.id)"
                        >
                            {{ mag.title }}
                        </button>
                    </div>

                    <div v-if="hebdomadaires.length > 0" class="list-group mb-2">
                        <button type="button" class="list-group-item list-group-item-action active py-1">
                            Toutes les semaines
                        </button>
                        <button
                            v-for="(mag, i) in hebdomadaires"
                            :key="i"
                            class="list-group-item list-group-item-action"
                            type="button"
                            @click="$router.push('/titre/' + mag.id)"
                        >
                            {{ mag.title }}
                        </button>
                    </div>

                    <div v-if="biMensuels.length > 0" class="list-group mb-2">
                        <button type="button" class="list-group-item list-group-item-action active py-1">
                            Deux fois par mois
                        </button>
                        <button
                            v-for="(mag, i) in biMensuels"
                            :key="i"
                            type="button"
                            class="list-group-item list-group-item-action"
                            @click="$router.push('/titre/' + mag.id)"
                        >
                            {{ mag.title }}
                        </button>
                    </div>

                    <div v-if="mensuels.length > 0" class="list-group mb-2">
                        <button type="button" class="list-group-item list-group-item-action active py-1">
                            Tous les mois
                        </button>
                        <button
                            v-for="(mag, i) in mensuels"
                            :key="i"
                            type="button"
                            class="list-group-item list-group-item-action"
                            @click="$router.push('/titre/' + mag.id)"
                        >
                            {{ mag.title }}
                        </button>
                    </div>
                </div>
                <div v-else class="mt-3 text-center">
                    <b-spinner label="Spinner" />
                </div>

                <!-- desktop-->
                <MainTitle title="Vous informer" text="" class="mt-5 d-sm-none d-md-block" />
                <a
                    v-for="(document, i) in documents"
                    :key="i"
                    :href="document.url"
                    target="_blank"
                    class="btn-block btn-light mt-3 py-2 px-3 d-sm-none d-md-block"
                    style="letter-spacing: 0"
                >
                    <i class="fa fa-file-pdf-o mr-3" aria-hidden="true" />{{ document.label }}
                </a>
                <!-- /desktop-->
            </b-col>
            <b-col md="6">
                <MainTitle
                    title="Absences"
                    text="Pour éviter de recevoir des appels de notre part quand vous n’êtes pas là."
                    class="mt-4"
                />

                <div v-if="!absLoading" class="mb-2">
                    <h5>
                        prochaine<span v-if="absences.length > 1">s</span>&nbsp;
                        absence<span v-if="absences.length > 1">s</span>&nbsp;
                        déclarée<span v-if="absences.length > 1">s</span>
                    </h5>
                    <p v-if="absences.length === 0">Pas d'absence prévue.</p>
                    <div v-for="(absence, i) in absences" v-else :key="i" class="border-0 bg-light mt-3">
                        <div v-if="moment(absence.fin, 'DD/MM/YYYY').isAfter(moment())" class="card-body d-flex justify-content-between py-2">
                            <div>{{ absence.debut }}</div>
                            <div><i class="fa fa-long-arrow-right" aria-hidden="true" /></div>
                            <div>{{ absence.fin }}</div>
                        </div>
                    </div>
                    <a class="btn btn-primary mt-4" @click="$router.push('absences')">Mes absences et disponibilités</a>
                </div>
                <div v-else class="mt-3 text-center">
                    <b-spinner label="Spinner" />
                </div>

                <!-- mobile-->
                <MainTitle title="Vous informer" text="" class="mt-5 d-md-none" />
                <a
                    v-for="(document, i) in documents"
                    :key="i"
                    :href="document.url"
                    target="_blank"
                    class="btn-block btn-light mt-3 py-2 px-3 d-md-none"
                    style="letter-spacing: 0"
                >
                    <i class="fa fa-file-pdf-o mr-3" aria-hidden="true" />{{ document.label }}
                </a>
                <!-- /mobile-->

                <MainTitle title="Vidéos du manuel panéliste" text="Vous pouvez visualiser le manuel panéliste selon le chapitre qui vous intéresse :" class="mt-5" />
                <a target="_blank" href="https://www.youtube.com/embed/5_RF8FGIqsQ" class="btn-block btn-light mt-3 py-2 px-3" style="letter-spacing: 0"><i class="fa fa-youtube-play mr-3" aria-hidden="true" />Votre rôle de panéliste</a>
                <a target="_blank" href="https://www.youtube.com/embed/j3FIsBf7WGY" class="btn-block btn-light mt-3 py-2 px-3" style="letter-spacing: 0"><i class="fa fa-youtube-play mr-3" aria-hidden="true" />Le carnet de bord</a>
                <a target="_blank" href="https://www.youtube.com/embed/mEuT6VL4HCY" class="btn-block btn-light mt-3 py-2 px-3" style="letter-spacing: 0"><i class="fa fa-youtube-play mr-3" aria-hidden="true" />Faire vos déclarations</a>
                <a target="_blank" href="https://www.youtube.com/embed/g16ZDP3pyDQ" class="btn-block btn-light mt-3 py-2 px-3" style="letter-spacing: 0"><i class="fa fa-youtube-play mr-3" aria-hidden="true" />Vos absences</a>
                <a target="_blank" href="https://www.youtube.com/embed/jPvZoqhbvLg" class="btn-block btn-light mt-3 py-2 px-3" style="letter-spacing: 0"><i class="fa fa-youtube-play mr-3" aria-hidden="true" />F.A.Q</a>

                <p class="mt-5 pl-5">
                    <small>
                        <i class="fa fa-info-circle ml-n4 mr-2" aria-hidden="true" />&nbsp;&nbsp;Si vos coordonnées
                        personnelles (adresse, téléphone fixe, portable, email) changent, merci de prévenir les
                        opérateurs par téléphone ou par mail afin de réaliser une mise à jour.
                    </small>
                </p>
            </b-col>
        </b-row>
    </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle'

export default {
    components: {
        MainLayout,
        MainTitle
    },
    data: () => ({
        mensuels: [],
        biMensuels: [],
        hebdomadaires: [],
        biHebdomadaires: [],
        quotidiens: [],
        moment
    }),
    created() {
        this.getWelcomeMessage()
        this.fetchAbsences()
        this.fetchCourriers().then(() => {
            this.mensuels = this.courriers.filter(courrier => courrier.periodicite === 'Mensuel')
            this.biMensuels = this.courriers.filter(courrier => courrier.periodicite === 'Bi-mensuel')
            this.hebdomadaires = this.courriers.filter(courrier => courrier.periodicite === 'Hebdomadaire')
            this.biHebdomadaires = this.courriers.filter(courrier => courrier.periodicite === 'Bi-hebdomadaire')
            this.quotidiens = this.courriers.filter(courrier => courrier.periodicite === 'Quotidien')
        })
    },
    computed: mapGetters({
        user: 'user/user',
        welcomeMessage: 'content/welcomeMessage',
        documents: 'content/documents',
        loading: 'content/loading',
        absences: 'absences/absences',
        absLoading: 'absences/loading',
        courriers: 'courriers/courriers',
        courrierLoading: 'courriers/loading'
    }),
    methods: mapActions({
        getWelcomeMessage: 'content/getWelcomeMessage',
        fetchAbsences: 'absences/fetchAbsences',
        fetchCourriers: 'courriers/fetchCourriers'
    })
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';

.magazines {
    max-width: 450px;

    .list-group-item {
        border: none;

        &.active {
            cursor: default;
            font-weight: bold;
            border-radius: $border-radius;
            background-color: $dark;
        }
    }
}
</style>
