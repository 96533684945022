import axios from 'axios'

const auth = {
    namespaced: true,
    state: () => ({
        isAuth: false,
        loading: false,
        alert: null
    }),
    getters: {
        isAuth: state => state.isAuth,
        loading: state => state.loading,
        alert: state => state.alert
    },
    mutations: {
        setAuth: (state, payload) => (state.isAuth = payload),
        setLoading: (state, payload) => (state.loading = payload),
        setAlert: (state, payload) => (state.alert = payload)
    },
    actions: {
        /**
         * Log user from the jwt stored & Avoid logout at refresh
         * @param commit
         * @param dispatch
         */
        initAuth({ commit, dispatch }) {
            commit('setLoading', true)
            commit('setAlert', null)

            if (localStorage.getItem('token')) {
                commit('setAuth', true)
                axios.defaults.headers.common.Authorization = localStorage.getItem('token')
                return dispatch('user/fetchUser', null, { root: true }).then(() => commit('setLoading', false))
            } else if (sessionStorage.getItem('token')) {
                commit('setAuth', true)
                axios.defaults.headers.common.Authorization = sessionStorage.getItem('token')
                return dispatch('user/fetchUser', null, { root: true }).then(() => commit('setLoading', false))
            } else {
                commit('setAuth', false)
                commit('setLoading', false)
            }
            return Promise.resolve()
        },
        /**
         * Login hanlder
         * @param commit
         * @param dispatch
         * @param {{identifier: string, password: string}} payload
         * @returns {Promise}
         */
        login({ commit, dispatch }, payload) {
            commit('setLoading', true)
            return axios({
                method: 'get',
                url: '/auth/ping',
                withCredentials: false
            }).then(() => {
                return axios
                    .post('/auth/login', payload)
                    .then(({ data }) => {
                        // noinspection JSUnresolvedVariable
                        axios.defaults.headers.common.Authorization = data.jwt

                        // noinspection JSUnresolvedVariable
                        if (payload.remember) {
                            localStorage.setItem('token', data.jwt)
                        } else {
                            sessionStorage.setItem('token', data.jwt)
                        }

                        commit('setAlert', null)
                        commit('setAuth', true)
                        commit('setLoading', false)

                        return dispatch('user/fetchUser', null, { root: true })
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        commit(
                            'setAlert',
                            e.request && e.request.status && e.request.status === 401
                                ? { ...e, text: `Mauvais identifiant ou mot de passe` }
                                : { ...e, text: `Une erreur s'est produite` }
                        )
                        return Promise.reject()
                    })
                })
        },
        /**
         * Logout handler
         * @param commit
         * @returns {Promise<void>}
         */
        logout({ commit }) {
            commit('setAuth', false)
            delete axios.defaults.headers.common.Authorization
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            return Promise.resolve()
        },
        /**
         * Déclanche coté API l'envoi d'email de reinitialisation de MdP
         * @param commit
         * @param panelisteId
         * @returns {Promise<AxiosResponse>}
         */
        sendResetMail({ commit }, panelisteId) {
            commit('setLoading', true)
            return axios({
                method: 'get',
                url: '/auth/ping',
                withCredentials: false
            }).then(() => {
                return axios
                    .post('/auth/sendresetmail', { idEmail: panelisteId })
                    .then(() => {
                        commit('setLoading', false)
                        commit('setAlert', null)
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        commit(
                            'setAlert',
                            e.request && e.request.status && e.request.status === 400
                                ? { ...e, text: `Mauvais identifiant` }
                                : { ...e, text: `Une erreur s'est produite` }
                        )
                        return Promise.reject()
                    })
                })
        },
        /**
         * Suite à un reset/oubli de mot de passe
         * @param commit
         * @param payload
         * @returns {Promise<AxiosResponse>}
         */
        renewPassword({ commit }, payload) {
            return axios.post('/auth/resetpw', payload).catch(e => {
                commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                return Promise.reject()
            })
        },
        /**
         * Changement de mot de passe (page profil)
         * @param commit
         * @param payload
         * @returns {Promise<AxiosResponse>}
         */
        changePassword({ commit }, payload) {
            return axios.post('/auth/changepw', payload).then(() => {
                    commit('setAlert', {
                        type: 'succes',
                        text: 'Le nouveau mot de passe a été enregistré avec succès...'
                    })
            }).catch(e => {
                    commit('setAlert', { ...e, text: `Une erreur s'est produite` })
                    return Promise.reject()
                })
        }
    }
}

export default auth
