<template>
    <div class="main-layout pt-5">
        <Header :logout="logout" :userInitals="userInitals" />
        <main class="container mt-xs-2 mt-sm-4 mt-md-4 mt-lg-5 mb-0 px-2 px-sm-5 py-4 bg-white">
            <Logos />
            <slot v-if="!loading" />
            <div v-else class="text-center mt-5 pt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div class="container position-fixed go-top-wrapper">
                <go-top
                    fg-color="#212529"
                    bg-color="#fecb00"
                    radius="0.25rem"
                    right="96px"
                    :size="50"
                    :max-width="100"
                />
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
// https://vuejsexamples.com/scroll-to-page-top-button-with-vue-js/
import GoTop from '@inotom/vue-go-top'
import Header from '../components/Header'
import Logos from '../components/Logos'
import Footer from '../components/Footer'

export default {
    components: {
        GoTop,
        Header,
        Logos,
        Footer
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        userInitals() {
            return this.user
                ? `${this.user.firstName.substr(0, 1)} ${this.user.lastName.substr(0, 1)}`.toUpperCase()
                : ''
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout').then(() => this.$router.push('/login'))
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/bootstrap';

.main-layout {
    main {
        min-height: calc(100vh - 414px);
        border-radius: 0;
        box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);

        @include media-breakpoint-up(sm) {
            border-radius: $border-radius $border-radius 0 0;
            min-height: calc(100vh - 362px);
        }
    }

    .go-top-wrapper {
        bottom: -80px;
        z-index: 2;

        .vue-go-top {
            position: absolute;
        }

        @include media-breakpoint-down(sm) {
            right: -73px;
        }
    }
}
</style>
