import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import Notifications from 'vue-notification'

import moment from 'moment'
moment.locale('fr')

Vue.config.productionTip = false

// https://github.com/euvl/vue-notification
Vue.use(Notifications)

Vue.use(BootstrapVue)

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
