<template>
    <PreLayout>
        <p class="text-lowercase">
            <span class="text-">{{ user.firstName }}</span>
            <span class="text-uppercase"> {{ user.lastName }}</span
            >, bienvenue sur le site de la mesure Presse.
        </p>
        <MainTitle
            title="Confirmation de votre participation"
            text="Vous vous connectez pour la première fois sur ce site internet,
            nous vous remercions de bien vouloir confirmer votre participation à l’étude."
        />
        <p class="mt-4">
            Je vous confirme ma participation à cette étude et vous autorise à transmettre mes coordonnées aux éditeurs
            des titres de presse auxquels je serai abonné.
        </p>
        <p>
            Je prends acte que CSA et La Poste s’engagent à limiter l’utilisation de mes informations personnelles au
            seul cadre de cette étude.
        </p>
        <p>
            Conformément à l’article 34 de la loi du 6 janvier 1978 sur l’informatique et liberté, je bénéficie d’un
            droit d’accès et de rectification de mes informations personnelles.
        </p>
        <p>
            Par ailleurs, nous vous demandons de nous renvoyer l'accord de participation papier le plus rapidement
            possible (<a :href="`${apiURL}/files/AccordParticipation.pdf`">le télécharger</a>).
        </p>

        <div class="row justify-content-between my-4 px-3">
            <button type="button" class="btn btn-secondary mt-4" @click="confirmAccord('declined')">Je refuse</button>
            <button type="submit" class="btn btn-primary mt-4" @click="confirmAccord('accepted')">
                <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                Je confirme
            </button>
        </div>
    </PreLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PreLayout from '../components/PreLayout.vue'
import MainTitle from '../components/MainTitle'

export default {
    components: {
        MainTitle,
        PreLayout
    },
    data: () => ({ apiURL: process.env.VUE_APP_API_URL }),
    computed: mapGetters({
        loading: 'user/altLoading',
        user: 'user/user'
    }),
    methods: {
        ...mapActions({
            sendAccord: 'user/sendAccord'
        }),
        confirmAccord(response) {
            this.sendAccord({ response }).then(resp => {
                if (resp === 'accepted') {
                    if (!this.user.validationDonnees) this.$router.push('/validation')
                    else this.$router.push('/')
                } else this.$router.push('/login')
            })
        }
    }
}
</script>
