<template>
    <b-row class="mb-3">
        <b-col>
            <h2 v-html="title" />
            <div class="p-wrapper">
                <p v-if="text" v-html="text" />
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        title: String,
        text: String
    }
}
</script>

<style lang="scss" scoped>
p {
    position: relative;
    padding-left: 14px;

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 1px;
        height: 80%;
        border-left: 3px solid #fecb00;
    }
}
</style>
