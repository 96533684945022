<template>
    <PreLayout>
        <b-form class="col-xs-12 col-sm-7 col-md-6 col-lg-8 mx-auto" @submit.prevent="onSubmit">
            <MainTitle title="Connexion" text="" class="ml-n1 text-center" />
            <b-form-group id="id-group" class="position-relative mt-4" label="Numéro de panéliste" label-for="id">
                <b-form-input
                    id="id"
                    v-model="id"
                    type="text"
                    class="pl-5"
                    required
                    placeholder="Saisissez votre identifiant"
                />
                <UserIcon size="1.1x" class="position-absolute" style="top: 44px; left: 17px" />
            </b-form-group>

            <b-form-group id="password-group" class="position-relative" label="Mot de passe" label-for="password">
                <b-form-input
                    id="password"
                    v-model="password"
                    :type="pwType"
                    class="pl-5"
                    required
                    placeholder="Saisissez votre mot de passe"
                />
                <EyeOffIcon
                    v-if="visiblePW"
                    class="position-absolute cursor-pointer"
                    style="top: 44px; left: 17px"
                    size="1x"
                    @click="toggleVisibleOff"
                />
                <EyeIcon
                    v-else
                    size="1x"
                    style="top: 44px; left: 17px"
                    class="position-absolute cursor-pointer"
                    @click="toggleVisibleOff"
                />
            </b-form-group>

            <b-form-group>
                <b-form-checkbox v-model="remember"> Rester connecté </b-form-checkbox>
            </b-form-group>

            <router-link to="/reset-pw">Mot de passe oublié ?</router-link>

            <b-row class="d-flex flex-row-reverse">
                <b-button type="submit" variant="primary" class="mt-4 mr-3">
                    <b-spinner v-if="loading" small />
                    Se connecter
                </b-button>
            </b-row>
        </b-form>
    </PreLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PreLayout from '../components/PreLayout'
import MainTitle from '../components/MainTitle'
import { UserIcon, EyeIcon, EyeOffIcon } from 'vue-feather-icons'

export default {
    components: {
        PreLayout,
        MainTitle,
        UserIcon,
        EyeIcon,
        EyeOffIcon
    },
    props: {
        title: String
    },
    data: () => ({
        id: '',
        password: '',
        remember: false,
        visiblePW: false
    }),
    computed: {
        ...mapGetters({ loading: 'auth/loading', isAuth: 'auth/isAuth', user: 'user/user' }),
        validLogin() {
            return (this.id !== '' || this.id.length > 5) && !isNaN(this.id)
        },
        validPassword() {
            return this.password !== '' || this.password.length > 3
        },
        pwType() {
            return this.visiblePW ? 'text' : 'password'
        }
    },
    mounted() {
        if (localStorage.getItem('remember')) {
            this.remember = true
        }
    },
    methods: {
        ...mapActions({ login: 'auth/login' }),
        onSubmit() {
            if (this.validLogin && this.validPassword)
                this.login({
                    login: this.id,
                    password: this.password,
                    remember: this.remember
                })
                    .then(() => {
                        if (!this.user.accordParticipation) {
                            this.$router.push('/accord')
                        } else if (!this.user.validationDonnees) {
                            this.$router.push('/validation')
                        } else if (this.user.mdpRedirect) {
                            this.$notify({
                                title: 'Attention !',
                                text: 'Nous vous invitons à modifier votre mot de passe pour des raisons de sécurité',
                                duration: 15000
                            })
                            this.$router.push('/profil')
                        } else this.$router.push('/')
                    })
                    .catch(console.log)
            else
                this.$notify({
                    text: `L'identifiant ou le mot de passe est invalide`,
                    type: 'error'
                })
        },
        toggleVisibleOff() {
            this.visiblePW = !this.visiblePW
        }
    }
}
</script>

<style scoped></style>
