<template>
    <PreLayout>
        <MainTitle title="Error 404" text="La page que vous essayez d'atteindre n'existe pas." />
        <button class="btn btn-secondary" @click="() => $router.go(-1)">Retour</button>
    </PreLayout>
</template>

<script>
import PreLayout from '../components/PreLayout.vue'
import MainTitle from '../components/MainTitle'

export default {
    components: {
        MainTitle,
        PreLayout
    }
}
</script>
