<template>
    <MainLayout :loading="loading">
        <MainTitle title="Newsletters" text="" class="mb-5" />
        <div class=" mb-5">
            <a v-for="newsletter in newsletters" :key="newsletter.order" :href="newsletter.link">
                <b-card class="box-shadow mt-3">
                    <b-card-text>
                        <i class="fa fa-file-pdf-o mr-3" aria-hidden="true" />{{ newsletter.title }}
                    </b-card-text>
                </b-card>
            </a>
        </div>
    </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle.vue'

export default {
    components: {
        MainLayout,
        MainTitle
    },
    created() {
        this.fetchNewsletters()
    },
    computed: mapGetters({ newsletters: 'content/newsletters', loading: 'content/loading' }),
    methods: mapActions({ fetchNewsletters: 'content/fetchNewsletters' })
}
</script>
