import axios from 'axios'

const content = {
    namespaced: true,
    state: () => ({
        newsletters: [],
        welcomeMessage: '',
        documents: [
            { label: `Manuel du panéliste`, url: process.env.VUE_APP_BO_URL + '/files/ManuelPaneliste.pdf' },
            {
                label: `Accord de participation`,
                url: process.env.VUE_APP_BO_URL + '/files/Accord_participation_QSP_nouveau_logo_CSA.pdf'
            },
            {
                label: `Lettre d'accompagnement`,
                url: process.env.VUE_APP_BO_URL + '/files/Lettre_d_accompagnement_QSP_nouveau_logo.pdf'
            }
        ],
        loading: false,
        alert: null
    }),
    getters: {
        newsletters: state => state.newsletters,
        welcomeMessage: state => state.welcomeMessage,
        documents: state => state.documents,
        loading: state => state.loading,
        alert: state => state.alert
    },
    mutations: {
        setNewsletters: (state, payload) => {
            const newsletters = []
            for (const newsletter in payload) {
                if (payload.hasOwnProperty(newsletter)) {
                    newsletters.unshift({
                        order: payload[newsletter].ordre,
                        title: newsletter,
                        link: process.env.VUE_APP_BO_URL + '/files/' + payload[newsletter].lien
                    })
                }
            }
            newsletters.sort((a, b) => {
                if (a.order < b.order) return -1
                if (a.order > b.order) return 1
                return 0
            })
            newsletters.length = 20
            state.newsletters = newsletters
        },
        setWelcomeMessage: (state, payload) => (state.welcomeMessage = payload),
        setLoading: (state, payload) => (state.loading = payload),
        setAlert: (state, payload) => (state.alert = payload)
    },
    actions: {
        /**
         * Fetch newsletters from api
         * @param commit
         * @param dispatch
         */
        fetchNewsletters({ commit }) {
            commit('setAlert', null)
            commit('setLoading', true)
            return axios
                .get('/content/newsletters')
                .then(({ data }) => {
                    commit('setNewsletters', data)
                    commit('setLoading', false)
                })
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        },
        /**
         * Message HTML de l'accueil
         * @param commit
         * @returns {Promise<AxiosResponse>}
         */
        getWelcomeMessage({ commit }) {
            commit('setAlert', null)
            return axios
                .get('/content/welcomeMessage')
                .then(({ data }) => commit('setWelcomeMessage', data))
                .catch(e => commit('setAlert', { ...e, text: `Une erreur s'est produite` }))
        }
    }
}

export default content
