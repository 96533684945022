<template>
    <MainLayout :loading="loading">
        <MainTitle title="Merci de vérifier et de compléter vos informations personnelles" />

        <b-form id="validationForm" @submit.prevent="onSubmit">
            <b-row>
                <b-col md="6">
                    <b-form-group
                        label="Disposez-vous d’une boite aux lettres normalisée, c’est-à-dire, permettant
                        à votre facteur d’introduire des petits paquets que vous pourriez recevoir ?"
                    >
                        <b-form-radio-group v-model="form.bal_normalise" required>
                            <b-form-radio value="1">oui</b-form-radio>
                            <b-form-radio value="0">non</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12">
                    <h3 class="mt-3">Pouvez-vous corriger les informations ci-dessous si elles sont erronées ?</h3>
                    <h4 class="mt-3">Votre identité</h4>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Civilité:" class="mt-2">
                        <b-form-radio-group v-model="form.civilite_titre">
                            <b-form-radio value="Madame">Madame</b-form-radio>
                            <b-form-radio value="Mademoiselle">Mademoiselle</b-form-radio>
                            <b-form-radio value="Monsieur">Monsieur</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="6">
                    <b-input v-model="form.nom" class="" placeholder="Votre nom *" required />
                </b-col>
                <b-col md="6" class="mt-3 mt-md-0">
                    <b-input id="firstname" v-model="form.prenom" class="" placeholder="Votre prénom *" required />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col md="12">
                    <h4>Votre adresse</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <b-input v-model="form.app_bal_etage_couloir_esc" class="mt-3" placeholder="Appartement" />
                </b-col>
                <b-col md="6" class="mt-3">
                    <b-input
                        v-model="form.entree_bat_immeuble_residence"
                        class=""
                        placeholder="Bâtiment / Immeuble / Résidence"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col md="6">
                    <b-input v-model="form.num_voie" class="" placeholder="Numéro de voie" />
                </b-col>
                <b-col md="6" class="mt-3 mt-md-0">
                    <b-input v-model="form.libelle_voie" class="" placeholder="Voie" />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col md="6">
                    <b-input v-model="form.lieu_dit_spd" class="" placeholder="Lieu dit" />
                </b-col>
                <b-col md="6" class="mt-3 mt-md-0">
                    <b-input v-model="form.code_postal" class="" placeholder="Code postal *" required />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col md="6">
                    <b-input v-model="form.localite" class="" placeholder="Ville" />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col md="12">
                    <h4>Vos coordonnées</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <b-input v-model="form.telephone" class="mt-3" placeholder="Téléphone *" required />
                </b-col>
                <b-col md="6" class="mt-3">
                    <b-input v-model="form.telephone_2" class="" placeholder="Téléphone 2" />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col md="6">
                    <b-input v-model="form.email" type="email" class="" placeholder="Email" />
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-right">
                    <button
                        v-b-tooltip.hover
                        type="submit"
                        class="btn btn-primary"
                        :title="isValidForm ? '' : 'Veuillez remplir tous les champs requis.'"
                        @click="scrollUp"
                    >
                        <span
                            v-if="submitLoading"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Valider
                    </button>
                </b-col>
            </b-row>
        </b-form>
    </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MainLayout from '../components/MainLayout.vue'
import MainTitle from '../components/MainTitle'

export default {
    components: {
        MainTitle,
        MainLayout
    },
    data: () => ({
        form: {}
    }),
    created() {
        this.fetchFullPaneliste()
            .then(() => (this.form = { ...this.fullUser }))
            .catch(console.log)
    },
    computed: {
        ...mapGetters({
            loading: 'user/loading',
            submitLoading: 'user/altLoading',
            fullUser: 'user/fullUser'
        }),
        isValidForm() {
            return (
                !!this.form.nom &&
                !!this.form.prenom &&
                !!this.form.code_postal &&
                !!this.form.telephone &&
                this.form.bal_normalise !== null
            )
        }
    },
    methods: {
        ...mapActions({
            fetchFullPaneliste: 'user/fetchFullPaneliste',
            sendValidation: 'user/sendValidation'
        }),
        onSubmit() {
            this.form.bal_normalise = this.form.bal_normalise ? !!+this.form.bal_normalise : false
            this.sendValidation(this.form).then(() => this.$router.push('/'))
        },
        scrollUp() {
            window.scrollTo(0, 120)
        }
    }
}
</script>
